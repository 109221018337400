export const convertEhrPatientToNovo = ehrPatient => {
  const genders = {
    M: 'MALE',
    F: 'FEMALE',
  }

  return {
    ...ehrPatient,
    firstName: ehrPatient.first,
    lastName: ehrPatient.last,
    displayName: ehrPatient.name,
    birthday: ehrPatient.dob,
    gender: genders[ehrPatient.sex],
  }
}
