<template>
  <bottom-sheet-panel tooltip="Tags" :activatorIcon="'mdi-tag-outline'" title="Patient Tags">
    <v-card flat class="flex pb-1">
      <v-text-field
        class="pb-4"
        persistent-hint
        placeholder="Add tags"
        hint="Separate multiple tags with commas. Press enter to submit."
        prepend-inner-icon="mdi-tag-plus-outline"
        background-color="grey lighten-4"
        solo
        flat
        @keydown.enter="submitTags()"
        @keydown.188="submitTags()"
        v-model="tagFieldText"
        clearable
      >
      </v-text-field>
      <v-card
        v-if="!props.patient.tags || props.patient.tags.length === 0"
        flat
        color="grey lighten-4"
        flex
        height="150"
      >
        <v-row justify="center" align="center" class="fill-height">
          <span>No tags yet</span>
        </v-row>
      </v-card>

      <v-chip
        color="primary"
        v-for="(tag, index) in props.patient.tags"
        :key="index"
        class="mr-2 mb-2"
        label
        close
        @click:close="removeTag(tag)"
        >{{ tag }}</v-chip
      >
    </v-card>
  </bottom-sheet-panel>
</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from '@vue/composition-api'
import BottomSheetPanel from './BottomSheetPanel.vue'
import { updatePatient } from '../shared-ui/store/patient'

export default defineComponent({
  components: { BottomSheetPanel },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  setup(props) {
    const tagFieldText = ref('')

    const removeTag = tag => {
      props.patient.tags = props.patient.tags.filter(e => e !== tag)
      updateThePatient()
    }
    const submitTags = () => {
      const tagArray = tagFieldText.value.split(',')
      tagArray.forEach(tag => {
        if (tag.length > 0 && tag !== ',') {
          props.patient.tags.push(tag)
        }
      })
      nextTick(() => {
        tagFieldText.value = ''
      })
      updateThePatient()
    }

    const updateThePatient = async () => {
      await updatePatient({ id: props.patient.id, tags: props.patient.tags })
    }

    onMounted(() => {})

    return {
      props,
      close,
      tagFieldText,
      submitTags,
      removeTag,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
