<template>
  <v-card rounded="lg" flat class="flex pt-4 pb-3">
    <v-row align="center" no-gutters>
      <v-avatar size="30" class="mr-3" color="primary"
        ><span class="font-weight-bold white--text" style="font-size: 12px;">{{
          userDict[note.userId].initials
        }}</span></v-avatar
      >

      <div style="font-weight: bold;">{{ userDict[note.userId].name }}</div>
      <v-spacer />
      <div>
        {{ returnRelativeTime(note.ts) }}
      </div>
    </v-row>

    <v-row class="py-4 text-body-1" no-gutters>
      {{ note.text }}
    </v-row>

    <v-card flat rounded="lg" color="grey lighten-4">
      <v-row v-for="(reply, index) in noteComments" :key="index" class="pt-2 px-3" no-gutters>
        <v-col>
          <v-row align="center" justify="space-between" class="text-subtitle-2 text--secondary" no-gutters>
            <span>{{ userDict[reply.userId].name }} </span
            ><span style="font-weight: 400; opacity: 0.8">{{ returnRelativeTime(reply.ts) }}</span>
          </v-row>
          <v-row class="text-body-2" no-gutters>
            {{ reply.text }}
          </v-row>
        </v-col>
      </v-row>
      <v-divider v-if="noteComments.length > 0" class="mt-2 mx-3" />
      <v-row no-gutters>
        <v-col>
          <v-card flat rounded="lg" color="green">
            <v-textarea
              ref="commentTextInput"
              background-color="grey lighten-4"
              hide-details
              solo
              flat
              placeholder="Add a comment..."
              auto-grow
              rows="1"
              row-height="12"
              class="text-body-2"
              v-model="newCommentInput"
              @keyup.enter="handleAction"
            >
            </v-textarea>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-if="needsReview" class="pt-3" no-gutters>
      <v-btn @click="handleAction" depressed color="primary" dark block
        ><span><span v-if="newCommentInput && newCommentInput !== ''">Submit and </span>mark as reviewed</span></v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { respondToNote } from '@/shared-ui/store/rpm'
import dayjs from 'dayjs'
dayjs.extend(relativeTime)
import relativeTime from 'dayjs/plugin/relativeTime'
import { updatePatient } from '../../shared-ui/store/patient'
import { dismissReviewNotification, addReviewNotification } from '@/shared-ui/store/tasks'
import { state as ssoState } from '../../shared-ui/store/sso'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export default defineComponent({
  props: {
    needsReview: false,
    patient: {
      type: Object,
      default: null,
    },
    userDict: {
      type: Object,
      default: null,
    },
    note: {
      type: Object,
      default: () => {
        return {
          ts: 1643920402,
          userID: '12345768',
          text: 'This is a beautiful note regarding a patient',
          tags: ['Critical'],
          comments: [{ ts: 1643920405, userID: '1234ss5768', text: 'and Here is a comment! ' }],
        }
      },
    },
  },
  setup(props) {
    const userInitials = ref('DB')
    const userName = ref('David Brodegard')
    const newCommentInput = ref(null)
    const commentTextInput = ref(null)
    const returnRelativeTime = ts => {
      return specificRelativeTimeWithHoursAndMinutes(ts)
    }

    // if the patient is in provider review, the first note in provider review should have

    const originalAuthorInitials = computed(() => {
      if (props.authorMode) {
        return userInitials
      } else {
        return props.note.userID
      }
    })

    const revealCommentBox = ref(false)

    const handleAction = async () => {
      if (newCommentInput.value && newCommentInput.value !== '') {
        await submitNoteResponse()
      } else {
        await dismissNotification()
      }
    }

    const submitNoteResponse = async () => {
      commentTextInput.value.blur()
      if (commentTextInput.value !== null && commentTextInput.value !== '') {
        const textToSubmit = newCommentInput.value
        newCommentInput.value = null
        props.note.comments.unshift({
          userId: ssoState.user.id,
          ts: dayjs().toISOString(),
          text: textToSubmit,
          tags: [],
          replies: [],
        })
        //patientId, noteUserId, noteTs, text
        await respondToNote(props.patient.id, props.note.userId, props.note.ts, textToSubmit)
        let notification = {
          org: ssoState.user.orgId,
          noteAuthor: props.note.userId,
          patient: props.patient.id,
          ts: props.note.ts,
          notificationTimestamp: new Date().getTime(),
          comment: true,
        }
        await addReviewNotification(notification)

        if (props.needsReview) {
          await dismissNotification()
        }
        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      }
    }

    const dismissNotification = async () => {
      //LEGACY CLEANUP: remove the provider review?
      // if (props.patient.tags.includes('Provider Review')) {
      //   props.patient.tags = props.patient.tags.filter(e => e !== 'Provider Review')

      //   let patientToModify = taskState.latestData.find(pt => pt.id === props.patient.id)
      //   patientToModify.tags = props.patient.tags

      //   await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      // }
      props.note.comments.unshift({
        userId: ssoState.user.id,
        ts: dayjs().toISOString(),
        text: 'Reviewed',
        tags: [],
        replies: [],
      })
      //patientId, noteUserId, noteTs, text
      await respondToNote(props.patient.id, props.note.userId, props.note.ts, 'Reviewed')

      await dismissReviewNotification(props.patient.id + '' + props.note.ts)
    }

    const noteComments = computed(() => {
      return props.note.comments.sort(function(a, b) {
        return new Date(a.ts).getTime() - new Date(b.ts).getTime()
      })
    })

    return {
      originalAuthorInitials,
      userName,
      submitNoteResponse,
      newCommentInput,
      commentTextInput,
      noteComments,
      returnRelativeTime,
      revealCommentBox,
      dismissNotification,
      handleAction,
    }
  },
})
</script>
