<template>
  <div>
    <portal to="logoSpace">
      <v-slide-x-reverse-transition hide-on-leave leave-absolute>
        <v-img v-if="ehrPatient" width="100" :src="require('@/assets/ArrowSmallLogo.png')" />
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition hide-on-leave leave-absolute>
        <v-btn style="margin-left: -12px;" @click="navigateToTaskboard" v-if="!ehrPatient" icon large
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
      </v-slide-x-reverse-transition>
    </portal>
    <portal to="pageTitle">
      {{ pageTitle }}
    </portal>
    <patient-chart :chartTab="chartTab" v-if="enrolledPatient" :patient="enrolledPatient" />
    <enrollment-flow v-if="enrollmentCandidate" :protoPatient="enrollmentCandidate" />
  </div>
</template>

<script>
import * as bridge from '@arrowhealth/bridge-sdk'
import { defineComponent, onMounted, ref, computed } from '@vue/composition-api'
import { router } from '@/router/index.js'
import { getPatient } from '@/shared-ui/store/rpm'
import PatientChart from '@/components/patientchart/PatientChart.vue'
import EnrollmentFlow from '@/components/enrollment/EnrollmentFlow.vue'
import { convertEhrPatientToNovo } from '@/shared-ui/helpers/patient'

export default defineComponent({
  components: { PatientChart, EnrollmentFlow },
  // get the patient from bridge. if there is no patient, then display the back button

  setup() {
    const ehrPatient = ref(null)
    const enrolledPatient = ref(null)
    const enrollmentCandidate = ref(null)

    const chartTab = ref(0)

    const setEnrolledPatient = async () => {
      if (router.history.current.params.id) {
        enrolledPatient.value = await getPatient(router.history.current.params.id)
        if (!enrolledPatient.value) {
          enrollmentCandidate.value = convertEhrPatientToNovo(ehrPatient.value)
        }
      }
    }
    onMounted(() => {
      bridge.getPatient().then(v => {
        ehrPatient.value = v
        setEnrolledPatient()
      })
      bridge.onPatientChanged(v => {
        ehrPatient.value = null
        enrolledPatient.value = null
        enrollmentCandidate.value = null
        ehrPatient.value = v
        setEnrolledPatient()
      })

      if (router.history.current.params.tab) {
        chartTab.value = router.history.current.params.tab
      }
    })

    const pageTitle = computed(() => {
      if (enrolledPatient.value) {
        return enrolledPatient.value.firstName + ' ' + enrolledPatient.value.lastName
      } else if (ehrPatient.value) {
        return ehrPatient.value.first + ' ' + ehrPatient.value.last
      } else {
        return null
      }
    })

    const navigateToTaskboard = () => {
      router.push({
        name: 'Taskboard',
      })
    }

    return { enrolledPatient, ehrPatient, pageTitle, navigateToTaskboard, enrollmentCandidate, chartTab }
  },
})
</script>

<style></style>
