<template>
  <div>
    <v-card color="taskboardCard" flat rounded="lg" @click="$emit('patient-click')">
      <v-row class="px-4 pl-3 py-1" no-gutters>
        <div>
          <v-row no-gutters justify="center">
            <v-btn class="mr-2" icon>
              <v-icon color="primary">mdi-text-box-outline</v-icon>
            </v-btn>
          </v-row>
        </div>
        <v-col>
          <v-row class="pt-2 pb-1" align="center" no-gutters>
            <span class="text-body-2 font-weight-regular"
              >{{ patient.firstName }} <span class="text-body-2 font-weight-bold">{{ patient.lastName }}</span></span
            >
            <v-spacer></v-spacer>

            <span class="text-body-2 font-weight-regular">{{ patient.noteForDisplay.date }}</span>

            <!-- <div
              v-if="patient.tags.includes('Provider Review')"
              style="font-size: 11px; position: relative; width: 60px; height:17px; color: white; font-weight: bold; background-color: #F44336; border-radius:4px; overflow:hidden"
            >
              <div
                style="position: absolute; top: 0px; left: 0px; text-align: center; width: 60px; background-color: #F44336"
              >
                Review
              </div>
            </div> -->

            <!-- <v-progress-linear :value="qhpPercentage" height="10" style="max-width: 60px;" rounded /> -->
          </v-row>
          <div class="pb-2">
            <v-row class="text--secondary" justify="space-between" no-gutters>
              <span class="text-body-2"
                ><span>{{ patient.noteForDisplay.author }}: </span>
                <span>
                  <span v-if="patient.noteForDisplay.text.length < 149" class="text-body-2">{{
                    patient.noteForDisplay.text
                  }}</span>
                  <span class="text-body-2" v-else>{{ patient.noteForDisplay.text.slice(0, 150) }}...</span>
                </span>
              </span>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="!lastOne" style="margin-left: 54px;" />
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    lastOne: {
      type: Boolean,
      default: false,
    },
  },

  setup() {},
})
</script>
<style scoped>
.card-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.005), 0 2px 2px rgba(0, 0, 0, 0.005), 0 4px 4px rgba(0, 0, 0, 0.005),
    0 8px 8px rgba(0, 0, 0, 0.005), 0 16px 16px rgba(0, 0, 0, 0.005);
  border-radius: 8px;
}
</style>
