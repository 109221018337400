<template>
  <v-card flat class="flex pb-1">
    <v-row align="center" style="width: 100%" class="pb-2" no-gutters>
      <span v-if="!patientStyle" class="text-body-2">Global Threshold Alert Rules</span>
      <span v-if="patientStyle" class="text-body-2">Threshold Alert Rules</span>
      <v-spacer />
      <v-btn small color="primary" @click="showDialog = true" text><v-icon small>mdi-plus</v-icon>add</v-btn>
    </v-row>
    <v-row
      align="center"
      no-gutters
      v-for="(rulesArrayItem, index) in rulesArray"
      v-show="rulesArrayItem.translatedRules.length > 0"
      :key="index + 'rules'"
    >
      <v-chip class="mb-2" color="grey lighten-3" label>{{ rulesArrayItem.title }}</v-chip>
      <v-chip
        v-for="(rule, index) in rulesArrayItem.translatedRules"
        :key="index + 'trans'"
        class="ml-2 mb-2"
        label
        close
        color="yellow"
        @click:close="removeThresholdAlert(rule), $emit('submitNew')"
        ><v-icon left v-if="rule.operator === 'above'">mdi-arrow-up</v-icon
        ><v-icon left v-if="rule.operator === 'below'">mdi-arrow-down</v-icon>{{ rule.rule }}</v-chip
      >
    </v-row>
    <v-card
      v-show="rulesArray.length === 0"
      height="100"
      flat
      rounded="lg"
      color="grey lighten-3"
      @click="showDialog = true"
    >
      <v-row no-gutters align="center" justify="center" class="fill-height text--disabled">
        Click to add a threshold
      </v-row>
    </v-card>

    <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          Add Threshold
        </v-card-title>
        <v-row class="px-4" no-gutters>
          <v-select
            :items="newRuleOptions"
            item-text="title"
            solo
            flat
            background-color="grey lighten-3"
            label="Metric Type"
            return-object
            v-model="selectedMetricRule"
            hide-details
            class="mb-4"
          >
          </v-select>
        </v-row>

        <v-card height="110" class="mb-4" flat>
          <v-row v-if="!selectedMetricRule" class="px-4" no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-select class="mr-3" solo flat background-color="grey lighten-4" hide-details :disabled="true" />
              </v-row>
            </v-col>
            <v-col>
              <v-row no-gutters>
                <v-text-field :disabled="true" hide-details class="mb-2" solo flat background-color="grey lighten-4" />
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="selectedMetricRule" class="px-4" no-gutters>
            <v-col>
              <v-row v-if="selectedMetricRule.title !== 'ECG'" no-gutters>
                <v-select
                  class="mr-3"
                  solo
                  flat
                  background-color="grey lighten-3"
                  label="Operator"
                  v-model="selectedOperator"
                  :items="operatorOptions"
                />
              </v-row>
            </v-col>
            <v-col v-if="selectedMetricRule.title === 'Blood Pressure'">
              <v-row no-gutters>
                <v-text-field
                  v-model="selectedSystolicValue"
                  hide-details
                  class="mb-2"
                  solo
                  flat
                  background-color="grey lighten-3"
                  label="Systolic"
                />
              </v-row>
              <v-row no-gutters>
                <v-text-field
                  v-model="selectedDiastolicValue"
                  hide-details
                  solo
                  flat
                  background-color="grey lighten-3"
                  label="Diastolic"
                />
              </v-row>
            </v-col>
            <v-col v-if="selectedMetricRule.title !== 'ECG' && selectedMetricRule.title !== 'Blood Pressure'">
              <v-row no-gutters>
                <v-text-field
                  type="number"
                  v-model="selectedValue"
                  hide-details
                  class="mb-2"
                  solo
                  flat
                  background-color="grey lighten-3"
                  label="Value"
                />
              </v-row>
            </v-col>
            <v-col v-if="selectedMetricRule.title === 'ECG'">
              <v-row no-gutters>
                <v-text-field
                  v-model="selectedValue"
                  hide-details
                  class="mb-2"
                  solo
                  flat
                  background-color="grey lighten-3"
                  label="Value"
                  readonly
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="pa-4" no-gutters>
          <v-btn @click="showDialog = false" class="flex" color="grey lighten-3" depressed>
            cancel
          </v-btn>
          <v-btn
            :disabled="!allowSubmit"
            @click=";(showDialog = false), updateThresholdObject(), $emit('submitNew')"
            class="flex ml-3"
            color="primary"
            depressed
          >
            Add Rule
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { watch, defineComponent, computed, ref, nextTick, onMounted } from '@vue/composition-api'
import CONSTS from '../consts'

export default defineComponent({
  components: {},
  props: {
    inboundRulesObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
    patientStyle: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    onMounted(() => {
      updateRulesArray()
    })
    const selectedMetricRule = ref(null)
    const selectedOperator = ref(null)
    const selectedValue = ref(null)
    const selectedSystolicValue = ref(null)
    const selectedDiastolicValue = ref(null)

    const showDialog = ref(false)
    const rulesArray = ref([])

    // reset the form as it is opened.

    watch(showDialog, showDialog => {
      if (showDialog) {
        nextTick(() => {
          selectedMetricRule.value = null
          selectedValue.value = null
          selectedOperator.value = null
          selectedSystolicValue.value = null
          selectedDiastolicValue.value = null
        })
      }
    })

    watch(selectedMetricRule, selectedMetricRule => {
      if (selectedMetricRule) {
        nextTick(() => {
          switch (selectedMetricRule.metric) {
            case 'BloodGlucose':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'BloodOxygen':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'BloodPressure':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'BodyTemp':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'Pulse':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'BodyWeight':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'Pain':
              selectedOperator.value = selectedMetricRule.operators[0]
              break
            case 'Ecg':
              selectedOperator.value = selectedMetricRule.operators[0]
              selectedValue.value = ['Possible AFIB']
              break
            default:
              selectedOperator.value = null
          }
        })
      }
    })

    const allowSubmit = computed(() => {
      if (selectedMetricRule.value !== null && selectedOperator.value !== null) {
        switch (selectedMetricRule.value.metric) {
          case 'BloodGlucose':
            return selectedValue.value !== null

          case 'BloodOxygen':
            return selectedValue.value !== null

          case 'BloodPressure':
            return selectedSystolicValue.value !== null && selectedDiastolicValue.value !== null

          case 'BodyTemp':
            return selectedValue.value !== null

          case 'Pulse':
            return selectedValue.value !== null

          case 'BodyWeight':
            return selectedValue.value !== null

          case 'Pain':
            return selectedValue.value !== null

          case 'Ecg':
            return selectedValue.value !== null

          default:
            return false
        }
      }
      return false
    })

    const removeThresholdAlert = alert => {
      console.log('removing alert', alert)
      if (alert) {
        switch (alert.metric) {
          case 'BloodGlucose':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'BloodOxygen':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'BloodPressure':
            if (alert.operator) {
              console.log('should be removing')
              props.inboundRulesObject[alert.metric][alert.operator] = {}
              console.log(props.inboundRulesObject[alert.metric])
            }
            break
          case 'BodyTemp':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'Pulse':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'BodyWeight':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'Pain':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = {}
            }
            break
          case 'Ecg':
            if (alert.operator) {
              props.inboundRulesObject[alert.metric][alert.operator] = []
            }
            break
          default:
            props.inboundRulesObject[alert.metric] = {}
            break
        }
      }
      updateRulesArray()
    }

    const updateThresholdObject = () => {
      if (selectedMetricRule.value !== null && selectedOperator.value !== null) {
        if (!props.inboundRulesObject[selectedMetricRule.value.metric]) {
          props.inboundRulesObject[selectedMetricRule.value.metric] = { above: {}, below: {}, isTrue: [], isFalse: [] }
        }
        switch (selectedMetricRule.value.metric) {
          case 'BloodGlucose':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value,
              }
            }
            break
          case 'BloodOxygen':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value,
              }
            }
            break
          case 'BloodPressure':
            if (selectedSystolicValue.value !== null && selectedSystolicValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                systolicValue: selectedSystolicValue.value,
                diastolicValue: selectedDiastolicValue.value,
              }
            }
            break
          case 'BodyTemp':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value,
              }
            }
            break
          case 'Pulse':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value,
              }
            }
            break
          case 'BodyWeight':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value / 2.20462262185,
              }
            }
            break
          case 'Pain':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = {
                value: selectedValue.value,
              }
            }
            break
          case 'Ecg':
            if (selectedValue.value !== null) {
              props.inboundRulesObject[selectedMetricRule.value.metric][selectedOperator.value] = ['afib']
            }
            break
          default:
            return {}
        }
      }
      updateRulesArray()
    }

    const updateRulesArray = () => {
      let internalRules = []
      Object.keys(props.inboundRulesObject).forEach(metric => {
        if (CONSTS.supportedMetrics[metric]) {
          let rulesObject = { ...props.inboundRulesObject[metric] }
          rulesObject.title = CONSTS.supportedMetrics[metric].commonName
          rulesObject.metric = metric

          rulesObject.translatedRules = []
          // first do above, then below, then istrue, isfalse
          switch (metric) {
            case 'BloodGlucose':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'above', rule: rulesObject.above.value })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'below', rule: rulesObject.below.value })
              }
              break
            case 'BloodOxygen':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'above', rule: rulesObject.above.value })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'below', rule: rulesObject.below.value })
              }
              break
            case 'BloodPressure':
              if (rulesObject.above.systolicValue && rulesObject.above.diastolicValue) {
                rulesObject.translatedRules.push({
                  metric: metric,
                  operator: 'above',
                  rule: rulesObject.above.systolicValue + '/' + rulesObject.above.diastolicValue,
                })
              }
              if (rulesObject.below.systolicValue && rulesObject.below.diastolicValue) {
                rulesObject.translatedRules.push({
                  metric: metric,
                  operator: 'below',
                  rule: rulesObject.below.systolicValue + '/' + rulesObject.below.diastolicValue,
                })
              }
              break
            case 'BodyTemp':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'above', rule: rulesObject.above.value })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'below', rule: rulesObject.below.value })
              }
              break
            case 'Pulse':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'above', rule: rulesObject.above.value })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'below', rule: rulesObject.below.value })
              }
              break
            case 'BodyWeight':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({
                  metric: metric,
                  operator: 'above',
                  rule: Math.round(10 * (rulesObject.above.value * 2.20462262185)) / 10,
                })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({
                  metric: metric,
                  operator: 'below',
                  rule: Math.round(10 * (rulesObject.below.value * 2.20462262185)) / 10,
                })
              }
              break
            case 'Pain':
              if (rulesObject.above.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'above', rule: rulesObject.above.value })
              }
              if (rulesObject.below.value) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'below', rule: rulesObject.below.value })
              }
              break
            case 'Ecg':
              if (rulesObject.isTrue.length > 0) {
                rulesObject.translatedRules.push({ metric: metric, operator: 'isTrue', rule: rulesObject.isTrue[0] })
              }
              break
            default:
              break
          }
          internalRules.push(rulesObject)
        }
      })

      rulesArray.value = internalRules
    }

    const newRuleOptions = computed(() => {
      let internalRules = []
      Object.keys(CONSTS.supportedMetrics).forEach(metric => {
        let rulesObject = {}
        rulesObject.title = CONSTS.supportedMetrics[metric].commonName
        rulesObject.metric = metric
        // first do above, then below, then istrue, isfalse
        switch (metric) {
          case 'BloodGlucose':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'BloodOxygen':
            rulesObject.operators = ['below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'BloodPressure':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = {
              above: { systolicValue: null, diastolicValue: null },
              below: { systolicValue: null, diastolicValue: null },
            }
            break
          case 'BodyTemp':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'Pulse':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'BodyWeight':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'Pain':
            rulesObject.operators = ['above', 'below']
            rulesObject.options = { above: { value: null }, below: { value: null } }
            break
          case 'Ecg':
            rulesObject.operators = ['isTrue']
            rulesObject.options = { isTrue: ['afib'] }
            break
          default:
            break
        }
        internalRules.push(rulesObject)
      })
      return internalRules
    })

    const operatorOptions = computed(() => {
      if (selectedMetricRule) {
        return selectedMetricRule.value.operators
      }
      return []
    })

    return {
      rulesArray,
      props,
      showDialog,
      selectedMetricRule,
      newRuleOptions,
      selectedOperator,
      selectedValue,
      selectedSystolicValue,
      selectedDiastolicValue,
      operatorOptions,
      allowSubmit,
      updateThresholdObject,
      updateRulesArray,
      removeThresholdAlert,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
