<template>
  <v-card v-if="readings[0]" class="flex" flat tile>
    <v-card height="55" flat tile>
      <v-row class="px-0 fill-height" align="center" justify="end" no-gutters>
        <!-- <v-btn small text outlined depressed v-if="zoomed" disabled>{{ filteredDays }} day</v-btn> -->
        <v-icon size="20" class="mr-2">{{ metric.icon }}</v-icon>
        <div class="font-weight-light text-h6">{{ metric.commonName }}</div>

        <v-spacer></v-spacer><span class="font-weight-light">{{ displayedTime }}</span>
      </v-row>
    </v-card>

    <v-row align="end" no-gutters class="text-h4  font-weight-light py-1">
      <div>
        {{ displayedValue }}<span class="text-body-1 ml-1">{{ metric.units }}</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="readings[0].data.pdfUrl"
        small
        class="ml-1"
        color="primary"
        icon
        @click="$emit('get-pdf', readings[0].data)"
      >
        <v-icon>mdi-file-pdf-box</v-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters class="pt-2">
      <v-chip v-if="readings[0].alert" color="yellow" small>
        <span>Out of Range</span>
      </v-chip>
    </v-row>

    <v-row class="pt-3" no-gutters>
      <v-btn small class="block flex" @click="copyLatestReading" depressed
        ><v-icon class="mr-1" x-small>mdi-content-copy</v-icon><span class="text--secondary">Copy Reading</span></v-btn
      >
    </v-row>

    <v-row no-gutters class="px-0 py-3">
      <v-card flat class="pt-0 flex" v-if="readings && readings.length">
        <v-row class="mt-2 text--secondary" justify="space-between" align="center" no-gutters>
          <div class="text-subtitle-2">{{ days }} Day Summary</div>
          <v-btn-toggle color="primary" v-model="days">
            <v-btn small :value="30">30</v-btn>
            <v-btn small :value="60">60</v-btn>
            <v-btn small :value="90">90</v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row no-gutters class="mt-3 pb-2 text-subtitle-2 text--secondary">
          <v-col cols="12" class="py-0">
            <v-row class="mb-1" no-gutters>
              <div>Readings:</div>
              <v-spacer></v-spacer>
              <div>{{ filteredReadings.length }}</div>
            </v-row>
            <v-row class="mb-1" v-if="!hideAverages" no-gutters>
              <div>High:</div>
              <v-spacer></v-spacer>
              <div v-if="metric.kind === 'BodyWeight'">{{ (high * 2.20462).toFixed(2) }}</div>
              <div v-else>{{ high }}</div>
            </v-row>
            <v-row class="mb-1" v-if="!hideAverages" no-gutters>
              <div>Low:</div>
              <v-spacer></v-spacer>
              <div v-if="metric.kind === 'BodyWeight'">{{ (low * 2.20462).toFixed(2) }}</div>
              <div v-else>{{ low }}</div>
            </v-row>
            <v-row class="mb-1" v-if="!hideAverages" no-gutters>
              <div>Average:</div>
              <v-spacer></v-spacer>
              <div v-if="metric.kind === 'BodyWeight'">{{ (average * 2.20462).toFixed(2) }}</div>
              <div v-else>{{ average }}</div>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { timeAgo } from '../shared-ui/helpers/data'
import CONSTS from '../consts.js'
import { processReading } from '../nurse-sidekick.js'
dayjs.extend(isBetween)

export default defineComponent({
  props: {
    kind: {
      type: String,
      default: '',
    },
    selectedReading: {
      type: Object,
      default: null,
    },
    readings: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const days = ref(90)
    const cardTitle = ref('')
    const displayTimeframe = ref(30)
    const hideAverages = ref(false)

    const daysSinceLastReading = computed(() => {
      if (props.readings.length) {
        let dateOfLatestReading = dayjs(props.readings[0].timestamp)
        return Math.abs(dateOfLatestReading.diff(new Date(), 'day'))
      }
      return ''
    })

    const formatTimestamp = (timestamp, ago) => {
      if (ago) {
        let ms = dayjs().diff(timestamp, 'milliseconds')
        return timeAgo(ms)
      }
      return dayjs(timestamp).format('MMM D YYYY h:mm a')
    }

    const displayedTime = computed(() => {
      if (props.readings[0]) {
        return dayjs(props.readings[0].timestamp).format('MMM D YYYY h:mm a')
      }
      return 'timestamp'
    })

    const displayedValue = computed(() => {
      if (props.readings[0]) {
        let reading = processReading(props.readings[0])
        if (reading && reading.data) {
          return reading.data
        }
        return 'no reading or no data'
      }
      return 'displayed'
    })

    const filteredReadings = computed(() => {
      let today = dayjs()
        .endOf('day')
        .valueOf()
      let start = dayjs()
        .startOf('day')
        .subtract(days.value, 'day')
        .valueOf()
      return props.readings.filter(data => {
        return dayjs(data.timestamp).isBetween(start, today)
      })
    })
    const values = computed(() => {
      if (typeMappingFullName[props.kind] === 'MEASUREMENT_BLOOD_PRESSURE') {
        return {
          systolic: filteredReadings.value.map(({ data }) => data?.systolicValue),
          diastolic: filteredReadings.value.map(({ data }) => data?.diastolicValue),
        }
      } else {
        return filteredReadings.value.map(({ data }) => data?.value)
      }
    })

    const average = computed(() => {
      const numOfReadings = filteredReadings.value.length
      if (typeMappingFullName[props.kind] === 'MEASUREMENT_BLOOD_PRESSURE') {
        return (
          Math.round(filteredReadings.value.reduce((a, b) => a + b.data.systolicValue, 0) / numOfReadings) +
          '/' +
          Math.round(filteredReadings.value.reduce((a, b) => a + b.data.diastolicValue, 0) / numOfReadings)
        )
      } else {
        return Math.round(filteredReadings.value.reduce((a, b) => a + b.data.value, 0) / numOfReadings)
      }
    })

    const high = computed(() => {
      if (typeMappingFullName[props.kind] === 'MEASUREMENT_BLOOD_PRESSURE') {
        return Math.max.apply(null, values.value.systolic) + '/' + Math.max.apply(null, values.value.diastolic)
      } else {
        return Math.max.apply(null, values.value)
      }
    })

    const low = computed(() => {
      if (typeMappingFullName[props.kind] === 'MEASUREMENT_BLOOD_PRESSURE') {
        return Math.min.apply(null, values.value.systolic) + '/' + Math.min.apply(null, values.value.diastolic)
      } else {
        return Math.min.apply(null, values.value)
      }
    })

    const metric = computed(() => {
      return CONSTS.supportedMetrics[props.kind]
    })

    const copyLatestReading = async () => {
      if (navigator) {
        try {
          let val
          if (typeMappingFullName[props.kind] === 'MEASUREMENT_BLOOD_PRESSURE') {
            val = `${props.readings[0].data.systolicValue}/${props.readings[0].data.diastolicValue}`
          } else {
            val = props.readings[0].data.value
          }

          let text = `${CONSTS.supportedMetrics[props.kind].abbreviation} ${val} ${dayjs(
            props.readings[0].timestamp
          ).format('MM/DD/YYYY HH:mm a')}`
          await navigator.clipboard.writeText(text)
          console.log('copied')
        } catch (error) {
          console.log(`Copy failed! ${error}`)
        }
      } else {
        console.log('copy is not supported')
      }
    }
    const typeMappingFullName = {
      MEASUREMENT_BLOOD_GLUCOSE: 'Bood Glucose',
      MEASUREMENT_BLOOD_PRESSURE: 'Blood Pressure',
      MEASUREMENT_PULSE: 'Heart Rate',
      MEASUREMENT_ECG: 'ECG',
      BoodGlucose: 'MEASUREMENT_BLOOD_GLUCOSE',
      BloodPressure: 'MEASUREMENT_BLOOD_PRESSURE',
      Pulse: 'MEASUREMENT_PULSE',
      Ecg: 'MEASUREMENT_ECG',
    }

    return {
      average,
      cardTitle,
      copyLatestReading,
      days,
      daysSinceLastReading,
      displayTimeframe,
      filteredReadings,
      formatTimestamp,
      hideAverages,
      high,
      low,
      metric,
      typeMappingFullName,
      props,
      displayedValue,
      displayedTime,
    }
  },
})
</script>
