export default {
  kgToLbs: 2.20462,
  metricList: {
    MEASUREMENT_BLOOD_PRESSURE: {
      abbreviation: 'BP',
      chartType: 'line',
      colors: ['#008FFB', '#00E396'],
      commonName: 'Blood Pressure',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart',
      iconColor: 'red',
      kind: 'BloodPressure',
      longName: 'Blood Pressure',
      order: 0,
      primaryMetric: true,
      units: 'mmHg',
      strokeColor: '#ffffff',
      cardColor: '#27A0FC',
    },
    MEASUREMENT_BLOOD_OXYGEN: {
      abbreviation: 'O2',
      chartType: 'line',
      colors: ['#008FFB', '#00E396'],
      commonName: 'Blood Oxygen',
      device: 'Pulse Oximiter',
      icon: 'mdi-lungs',
      iconColor: 'red',
      kind: 'BloodOxygen',
      longName: 'Blood Oxygen',
      order: 1,
      primaryMetric: true,
      units: 'SpO2',
      strokeColor: '#ffffff',
      cardColor: '#27A0FC',
    },
    MEASUREMENT_BLOOD_GLUCOSE: {
      abbreviation: 'GLU',
      chartType: 'line',
      colors: ['#07A880'],
      commonName: 'Glucose',
      device: 'Blood Glucose Meter',
      icon: 'mdi-water',
      iconColor: 'red',
      longName: 'Blood Glucose',
      order: 2,
      primaryMetric: true,
      units: 'mg/dL',
      kind: 'BloodGlucose',
      strokeColor: '#ffffff',
      cardColor: '#00C0AC',
    },
    MEASUREMENT_BODY_WEIGHT: {
      abbreviation: 'WT',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'Weight',
      device: 'Scale',
      icon: 'mdi-scale-bathroom',
      iconColor: 'grey darken-4',
      kind: 'Weight',
      longName: 'Weight',
      order: 3,
      primaryMetric: true,
      units: 'lbs',
      strokeColor: '#ffffff',
      cardColor: '#375094',
    },
    MEASUREMENT_ECG: {
      abbreviation: 'ECG',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'ECG',
      device: 'ECG',
      icon: 'mdi-clipboard-pulse',
      iconColor: 'grey darken-4',
      kind: 'Ecg',
      longName: 'ECG',
      order: 4,
      primaryMetric: true,
      units: 'bpm',
      strokeColor: '#ffffff',
      cardColor: '#000000',
    },
    MEASUREMENT_PULSE: {
      abbreviation: 'HR',
      chartType: 'line',
      colors: ['#E80305'],
      commonName: 'Heart Rate',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart-pulse',
      iconColor: 'red',
      kind: 'Pulse',
      longName: 'Heart Rate',
      order: 5,
      primaryMetric: false,
      units: 'bpm',
      strokeColor: '#ffffff',
      cardColor: '#EE1C24',
    },
    MEASUREMENT_PAIN: {
      abbreviation: 'PAIN',
      chartType: 'line',
      colors: ['#07A880'],
      commonName: 'Pain',
      device: 'Patient Reported',
      icon: 'mdi-water',
      iconColor: 'red',
      longName: 'Pain',
      order: 7,
      primaryMetric: true,
      units: 'out of 10',
      kind: 'Pain',
      strokeColor: '#ffffff',
      cardColor: '#00C0AC',
    },
  },
  supportedMetrics: {
    Ecg: {
      abbreviation: 'ECG',
      legacyName: 'MEASUREMENT_ECG',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'ECG',
      title: 'ECG',
      device: 'ECG',
      icon: 'mdi-pulse',
      iconColor: 'grey darken-4',
      kind: 'Ecg',
      longName: 'ECG',
      order: 10,
      primaryMetric: true,
      units: '',
      strokeColor: '#ffffff',
      cardColor: '#000000',
    },
    BloodOxygen: {
      abbreviation: 'O2',
      legacyName: 'MEASUREMENT_BLOOD_OXYGEN',
      chartType: 'line',
      colors: ['#008FFB', '#00E396'],
      commonName: 'Blood Oxygen',
      title: 'Blood Oxygen',
      device: 'Pulse Oximiter',
      icon: 'mdi-lungs',
      iconColor: 'red',
      kind: 'BloodOxygen',
      longName: 'Blood Oxygen',
      order: 20,
      primaryMetric: true,
      units: 'SpO2',
      strokeColor: '#ffffff',
      cardColor: '#27A0FC',
    },
    BloodPressure: {
      abbreviation: 'BP',
      legacyName: 'MEASUREMENT_BLOOD_PRESSURE',
      chartType: 'line',
      colors: ['#008FFB', '#00E396'],
      commonName: 'Blood Pressure',
      title: 'Blood Pressure',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart',
      iconColor: 'red',
      kind: 'BloodPressure',
      longName: 'Blood Pressure',
      order: 30,
      primaryMetric: true,
      units: 'mmHg',
      strokeColor: '#ffffff',
      cardColor: '#27A0FC',
    },
    Pulse: {
      abbreviation: 'HR',
      legacyName: 'MEASUREMENT_PULSE',
      chartType: 'line',
      colors: ['#E80305'],
      commonName: 'Heart Rate',
      title: 'Heart Rate',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart-pulse',
      iconColor: 'red',
      kind: 'Pulse',
      longName: 'Heart Rate',
      order: 40,
      primaryMetric: false,
      units: 'bpm',
      strokeColor: '#ffffff',
      cardColor: '#EE1C24',
    },
    BloodGlucose: {
      abbreviation: 'GLU',
      legacyName: 'MEASUREMENT_BLOOD_GLUCOSE',
      chartType: 'line',
      colors: ['#07A880'],
      commonName: 'Glucose',
      title: 'Blood Glucose',
      device: 'Blood Glucose Meter',
      icon: 'mdi-water',
      iconColor: 'red',
      longName: 'Blood Glucose',
      order: 50,
      primaryMetric: true,
      units: 'mg/dL',
      kind: 'BloodGlucose',
      strokeColor: '#ffffff',
      cardColor: '#00C0AC',
    },
    BodyWeight: {
      abbreviation: 'WT',
      legacyName: 'MEASUREMENT_BODY_WEIGHT',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'Weight',
      title: 'Weight',
      device: 'Scale',
      icon: 'mdi-scale-bathroom',
      iconColor: 'grey darken-4',
      kind: 'BodyWeight',
      longName: 'Weight',
      order: 60,
      primaryMetric: true,
      units: 'lbs',
      strokeColor: '#ffffff',
      cardColor: '#375094',
    },

    // Pain: {
    //   abbreviation: 'PAIN',
    //   legacyName: 'MEASUREMENT_PAIN',
    //   chartType: 'line',
    //   colors: ['#07A880'],
    //   commonName: 'Pain',
    //   title: 'Pain',
    //   device: 'Patient Reported',
    //   icon: 'mdi-emoticon-frown',
    //   iconColor: 'red',
    //   longName: 'Pain',
    //   order: 70,
    //   primaryMetric: true,
    //   units: 'out of 10',
    //   kind: 'Pain',
    //   strokeColor: '#ffffff',
    //   cardColor: '#00C0AC',
    // },
  },
}
export const DEVICES = [
  {
    class: 'Blood Pressure Monitor',
    kind: 'SmartMeter_iBloodPressure_SMBP802dashGS',
    title: 'Smart Meter iBloodPressure SMBP802-GS',
    subtitle: 'Cellular enabled',
    image: require('@/assets/smbp_150x150.png'),
    name: 'Smart Meter iBloodPressure',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  {
    class: 'Weight Scale',
    kind: 'BodyTrace_Scale_BT005',
    title: 'BodyTrace BT005',
    subtitle: 'Cellular enabled',
    image: require('@/assets/scale_150x150.png'),
    name: 'BodyTrace Weight Scale BT005',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  {
    class: 'Blood Pressure Monitor',
    kind: 'BodyTrace_BpCuff_BT105',
    title: 'BodyTrace BT105',
    subtitle: 'Cellular enabled',
    image: require('@/assets/bloodpressure_150x150.png'),
    name: 'BodyTrace BP Cuff BT105',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: false,
  },
  {
    class: 'ECG Monitor',
    kind: 'Lohman_AfibAlert_V1dot5',
    title: 'Lohman AfibAlert V1.5',
    subtitle: 'Bluetooth enabled',
    image: require('@/assets/ecg_150x150.png'),
    name: 'Lohman AfibAlert',
    idLabel: 'Device ID (6-digit alphanumeric)',
    idValidation: value => /^[a-zA-Z0-9]{6}$/.test(value),
    availableForOrder: false,
  },
  {
    class: 'Blood Glucose Meter',
    kind: 'SmartMeter_iGlucose_GM291',
    title: 'Smart Meter iGlucose GM291',
    subtitle: 'Cellular enabled',
    image: require('@/assets/glucose_150x150.png'),
    name: 'Smart Meter iGlucose',
    idLabel: 'Device ID Number (7-digit number)',
    idValidation: value => /^[0-9]{7}$/.test(value),
    availableForOrder: true,
  },
  {
    class: 'Pulse Oximeter',
    kind: 'SmartMeter_iPulseOx_SMPO1000dashUS',
    title: 'Pulse Oximeter: Smart Meter iPulseOx SMPO1000',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/smpo_150x150.png'),
    name: 'Smart Meter iPulseOx',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
]

export const DEVICES_KIND_MAP = {
  SmartMeter_iBloodPressure_SMBP802dashGS: DEVICES[0],
  BodyTrace_Scale_BT005: DEVICES[1],
  BodyTrace_BpCuff_BT105: DEVICES[2],
  Lohman_AfibAlert_V1dot5: DEVICES[3],
  SmartMeter_iGlucose_GM291: DEVICES[4],
  SmartMeter_iPulseOx_SMPO1000dashUS: DEVICES[5],
}

function validateIMEI(value) {
  // Must be 15 digits
  if (!/^[0-9]{15}$/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}
