<template>
  <v-card color="transparent" flat v-if="data && icon">
    <v-row align="center" no-gutters>
      <v-icon class="mr-2" color="red lighten-1" :size="18">{{ icon }}</v-icon>
      <div class="reading-display-data text-h6 font-weight-bold">
        {{ data }}<span class=" text-subtitle-2 text--secondary ml-1">{{ units }}</span>
      </div>
    </v-row>
    <v-row class="text-caption pl-7 text--secondary" style="margin-top:-4px" no-gutters>
      {{ time }}
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { toFahrenheit } from '../shared-ui/helpers/data.js'
import dayjs from 'dayjs'
import { timeAgo } from '../shared-ui/helpers/data.js'

export default defineComponent({
  props: {
    reading: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const kind = ref(null)
    const icon = ref(null)
    const data = ref(null)
    const time = ref(null)
    const title = ref(null)
    const units = ref('')

    onMounted(() => {
      let readingData = props.reading.data
      kind.value = props.reading.kind
      switch (kind.value) {
        case 'BloodGlucose':
          title.value = 'Blood Glucose'
          icon.value = 'mdi-water'
          data.value = parseInt(readingData.value)
          units.value = 'mg/dL'
          break
        case 'BloodPressure':
          title.value = 'Blood Pressure'
          icon.value = 'mdi-heart'
          data.value = `${readingData.systolicValue}/${readingData.diastolicValue}`
          units.value = 'mmHg'
          break
        case 'BodyTemp':
          title.value = 'Body Temperature'
          icon.value = 'mdi-thermometer-lines'
          data.value = toFahrenheit(readingData.value, true)
          break
        case 'Pulse':
          title.value = 'Pulse'
          icon.value = 'mdi-heart-pulse'
          data.value = parseInt(readingData.value)
          units.value = 'bpm'
          break
        case 'BodyWeight':
          title.value = 'Body Weight'
          icon.value = 'mdi-scale-bathroom'
          data.value = parseFloat(readingData.value).toFixed(2)
          units.value = 'lbs'
          break
        case 'Pain':
          title.value = 'Pain'
          icon.value = 'mdi-emoticon-frown'
          data.value = parseFloat(readingData.value).toFixed(1)
          units.value = 'out of 10'
          break
        case 'BodyHeight':
        case 'Ecg':
          title.value = 'Height'
          icon.value = 'mdi-pulse'
          units.value = ''
          if (readingData.afib) {
            data.value = 'Possible Afib'
          } else {
            data.value = 'No Afib Detected'
          }

          break
        default:
          return ''
      }

      time.value = timeAgo(dayjs().diff(props.reading.timestamp))
    })
    return {
      data,
      icon,
      title,
      time,
      units,
      props,
    }
  },
})
</script>
