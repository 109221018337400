import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, readonly } from '@vue/composition-api'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { onAuthChanged } from '../hooks'
import { teleClient } from './clients/tele'
import { getOrgId } from './sso'

Vue.use(VueCompositionAPI)

onAuthChanged(authUser => {
  if (!authUser) {
    clearMessages()
  }
})

const _state = reactive({
  convos: [],
  unreadConvosCount: 0,
  messages: [],
  unreadMessagesCount: 0,
  messengerOpenCount: 0,
})

export const state = readonly(_state)

const purgeTempMessages = (messages = []) => {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].id) {
      msgs.push(messages[i])
    }
  }
  return msgs
}

const parseMessages = (messages = []) => {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    let msg = messages[i]
    msgs.push({
      id: msg.id,
      senderId: msg.sender.userId,
      displayName: msg.sender.displayName,
      isSender: msg.sender.kind !== 'PATIENT',
      content: msg.content,
      delivered: get(msg, 'smsStatus', '').toLowerCase() === 'delivered',
      created: msg.created,
    })
  }
  return msgs
}

const setConversation = conversation => {
  _state.conversation = conversation
}

export const getConversation = async (patientId = '') => {
  const orgId = await getOrgId()
  let { data } = await teleClient.post('/GetConvo', {
    orgId,
    patientId,
  })
  setConversation(data.convo)
  return data.convo
}

const setMessages = messages => {
  messages = uniqBy(messages, 'id')
  messages = sortBy(messages, 'created')
  _state.messages = messages
}

export const clearMessages = () => {
  _state.messages = []
  _state.unreadMessagesCount = 0
  _state.messengerOpenCount = 0
}

export const getConvosCount = async (unreadOnly = true) => {
  const orgId = await getOrgId()
  const { data } = await teleClient.post('/GetConvosCount', { orgId, unreadOnly })
  _state.unreadConvosCount = data.count
  return data.count
}

export const getConvos = async (unreadOnly = false, pageToken = '', pageSize = 500) => {
  const orgId = await getOrgId()
  const { data } = await teleClient.post('/GetConvos', { orgId, unreadOnly, pageToken, pageSize })
  _state.convos = data.convos
  return data.convos
}

export const decMessengerOpenCount = () => {
  let count = _state.messengerOpenCount - 1
  if (count < 0) {
    console.warn('messenger count was less than 0')
    count = 0
  }
  if (count === 0) {
    clearMessages()
  }
  _state.messengerOpenCount = count
}

export const incMessengerOpenCount = () => {
  _state.messengerOpenCount++
}

export const getUnreadMessagesCount = async () => {
  if (!_state.conversation) {
    return 0
  }
  let newerThan = _state.conversation.orgLastRead
  let c = _state.messages.length
  if (c > 0) {
    let lastMessage = _state.messages[c - 1]
    newerThan = lastMessage.created
  }
  let { data } = await teleClient.post('/GetMsgsCount', {
    convoId: _state.conversation.id,
    newerThan,
  })

  setUnreadMessagesCount(data.count)
  return data.count
}

export const setUnreadMessagesCount = async count => {
  _state.unreadMessagesCount = Number(count)
}

export const getMessages = async patientId => {
  if (!_state.conversation) {
    await getConversation(patientId)
  }
  // still no convo, return empty messages
  if (!_state.conversation) {
    return _state.messages
  }
  let newerThan
  let c = _state.messages.length
  if (c > 0) {
    let lastMessage = _state.messages[c - 1]
    newerThan = lastMessage.created
  }

  const { data } = await teleClient.post('/GetMsgs', {
    convoId: _state.conversation.id,
    newerThan,
    pageSize: 100,
  })
  let messages = [].concat(_state.messages)
  if (_state.messages.length === 0 || newerThan) {
    messages = messages.concat(parseMessages(data.msgs))
    messages = purgeTempMessages(messages)
  }
  setMessages(messages)
  return messages
}

export const sendMessage = async (patientId, content) => {
  let orgId = await getOrgId()
  const { data } = await teleClient.post('/SendMsg', {
    orgId,
    patientId,
    content,
  })
  const messages = _state.messages.concat(parseMessages([data.msg]))
  setMessages(messages)
  return data.msg
}

let autoCheckTimer
export const startCheckForUnreadCount = async ({
  patientId = '',
  checkForUnreadInterval = 60000,
  checkImmediately = false,
}) => {
  clearInterval(autoCheckTimer)
  // get the conversation that belongs to the patient
  if (patientId && get(_state, 'conversation.patientId') !== patientId) {
    await getConversation(patientId)
  }
  //  start the auto check
  autoCheckTimer = setInterval(() => {
    getUnreadMessagesCount()
  }, checkForUnreadInterval)

  if (checkImmediately) {
    getUnreadMessagesCount()
  }
}
