<template>
  <v-sheet id="messagePage" color="transparent" flat class="flex px-4 py-4">
    <empty-page
      v-if="showMessages && conversationState && conversationState.messages && conversationState.messages.length === 0"
      title="No messages right now"
      detail="The ongoing text message conversation between the care navigators and the patient will be found here."
      :image="require('@/assets/conversation.svg')"
    />
    <v-row
      :justify="message.isSender ? 'end' : 'start'"
      v-for="(message, index) in conversationState.messages"
      :key="index"
      no-gutters
      class="pb-1"
    >
      <v-card
        max-width="70%"
        class="pa-2 px-4"
        style="border-radius: 20px;"
        :dark="message.isSender ? 'dark' : null"
        flat
        :color="message.isSender ? 'primary' : 'grey lighten-3'"
      >
        <span :ref="index === conversationState.messages.length - 1 ? 'newestMsg' : null"></span>
        <!-- <v-row no-gutters>{{ message }}</v-row> -->
        <v-row no-gutters>{{ message.content }}</v-row>
      </v-card>
    </v-row>
  </v-sheet>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

import { state as conversationState, getConversation, getMessages, clearMessages } from '@/shared-ui/store/conversation'
//import { state as orgState } from '@/shared-ui/store/org'
//import dayjs from 'dayjs'
import EmptyPage from '../EmptyPage.vue'
//import { getUserId } from '@/shared-ui/store/sso'

export default defineComponent({
  components: { EmptyPage },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const showMessages = ref(false)
    const newestMsg = ref(null)

    const scrollToNewest = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }

    onBeforeMount(async () => {
      await clearMessages()
      await getConversation(props.patient.id)
      await getMessages(props.patient.id)
      showMessages.value = true
      setTimeout(() => {
        scrollToNewest()
      }, 100)
    })
    return { conversationState, showMessages, scrollToNewest, newestMsg }
  },
})
</script>
