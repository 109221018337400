<template>
  <div>
    <portal to="logoSpace">
      <v-slide-x-reverse-transition hide-on-leave leave-absolute>
        <v-img width="100" :src="require('@/assets/ArrowSmallLogo.png')" />
      </v-slide-x-reverse-transition>
    </portal>
    <portal to="toolbarItems">
      <v-row no-gutters justify="end">
        <taskboard-settings-manager />
        <org-device-manager
          v-if="organizationDevices"
          @patient-click="patientFromDevice"
          :enrolledPatients="taskState.latestData"
          :devices="organizationDevices"
        />
        <v-tooltip v-if="false" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('refreshPatientList')" icon
              ><v-icon v-bind="attrs" v-on="on" v-if="!refreshingPatientList">mdi-refresh</v-icon
              ><v-progress-circular color="primary" v-if="refreshingPatientList" indeterminate size="18" width="2"
            /></v-btn>
          </template>
          Refresh
        </v-tooltip>
      </v-row>
    </portal>
    <v-row v-if="taskState.providerReviewNotifications.length > 0" class="my-4 novo-sticky-header" no-gutters>
      <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
        <v-row no-gutters align="center" justify="space-between">
          <v-chip color="primary" small style="font-weight: bold">PROVIDER REVIEW</v-chip>

          <v-chip color="primary" small style="font-weight: bold">{{ notesForProviderReview.length }}</v-chip>
        </v-row>
      </v-card>
    </v-row>

    <v-expand-transition>
      <div v-if="taskState.providerReviewNotifications.length > 0" class="mb-10">
        <note-row
          v-for="(note, index) in notesForProviderReview"
          :key="index + 'notifications'"
          @toggleStarred="toggleStarred"
          :patient="note"
          @patient-click="navigateToPatientNotes(note)"
          color="newAlert"
          :lastOne="index === notesForProviderReview.length - 1"
        />
      </div>
    </v-expand-transition>
    <v-slide-y-reverse-transition>
      <div v-if="taskState.latestData && taskState.latestData.length > 0">
        <div
          v-if="
            taskState.latestData.filter(patient => {
              return patient.tags.includes('Practice Starred')
            }).length > 0
          "
          class="mb-10"
        >
          <v-row class="my-4 novo-sticky-header" no-gutters>
            <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
              <v-chip color="grey lighten-2" small style="font-weight: bold">STARRED</v-chip>
            </v-card>
          </v-row>

          <v-lazy
            :options="{
              threshold: 0,
            }"
            min-height="65"
            v-for="(patient, index) in taskState.latestData.filter(patient => {
              return patient.tags.includes('Practice Starred')
            })"
            :key="index + 'starred'"
            v-show="patient.tags.includes('Practice Starred')"
            :transition="null"
          >
            <patient-row
              @toggleStarred="toggleStarred"
              :patient="patient"
              @patient-click="navigateToPatient(patient)"
              color="newAlert"
              :lastOne="
                index ===
                  taskState.latestData.filter(patient => {
                    return patient.tags.includes('Practice Starred')
                  }).length -
                    1
              "
            />
          </v-lazy>
        </div>
        <div
          v-if="
            taskState.latestData.filter(patient => {
              return patient.rpm.newAlerts
            }).length > 0
          "
          class="mb-10"
        >
          <v-row class="my-4 novo-sticky-header" no-gutters>
            <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
              <v-chip color="grey lighten-2" small style="font-weight: bold">RECENT ALERTS</v-chip>
            </v-card>
          </v-row>

          <v-lazy
            :options="{
              threshold: 0,
            }"
            min-height="65"
            v-for="(patient, index) in taskState.latestData.filter(patient => {
              return patient.rpm.newAlerts
            })"
            :key="index + 'newalert'"
            v-show="patient.rpm.newAlerts"
            :transition="null"
          >
            <patient-row
              @toggleStarred="toggleStarred"
              :patient="patient"
              @patient-click="navigateToPatient(patient)"
              color="newAlert"
              :alertType="true"
              :lastOne="
                index ===
                  taskState.latestData.filter(patient => {
                    return patient.rpm.newAlerts
                  }).length -
                    1
              "
            />
          </v-lazy>
        </div>
        <v-row class="mb-4 novo-sticky-header" no-gutters>
          <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
            <v-chip color="grey lighten-2" style="font-weight: bold" small>LATEST READINGS</v-chip>
          </v-card>
        </v-row>

        <v-lazy
          :options="{
            threshold: 0,
          }"
          min-height="65"
          v-for="(patient, index) in taskState.latestData"
          :key="index + 'latest'"
          :transition="null"
        >
          <patient-row
            @toggleStarred="toggleStarred"
            :patient="patient"
            @patient-click="navigateToPatient(patient)"
            color="newAlert"
          />
        </v-lazy>
      </div>
    </v-slide-y-reverse-transition>
    <v-fade-transition hide-on-leave>
      <div v-if="taskState.latestData && taskState.latestData.length === 0 && !taskState.refreshingData">
        <empty-page
          title="No tasks at the moment"
          detail="Data from each patient with a connected device, as well as any patients that need your attention will appear here."
          :image="require('@/assets/taskboard.svg')"
        >
          <v-btn
            :color="refreshingPatientList ? 'grey lighten-3' : 'primary'"
            @click="refreshingPatientList"
            depressed
            rounded
            >Refresh</v-btn
          >
        </empty-page>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

import TaskboardSettingsManager from '../TaskboardSettingsManager.vue'
import { updatePatient } from '../../shared-ui/store/patient'
import PatientRow from './PatientRow.vue'
import NoteRow from './NoteRow.vue'
import EmptyPage from '../EmptyPage.vue'
import OrgDeviceManager from '../OrgDeviceManager.vue'
import { state as taskState, getPatientDict } from '@/shared-ui/store/tasks'
import { state as orgState } from '@/shared-ui/store/org'
import { getOrganizationDevices } from '@/shared-ui/store/rpm'
import { router } from '@/router/index.js'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default defineComponent({
  components: { TaskboardSettingsManager, PatientRow, EmptyPage, OrgDeviceManager, NoteRow },
  props: {
    refreshingPatientList: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const organizationDevices = ref(null)
    const fetchingPatients = ref(false)

    const activePatientsToday = computed(() => {
      return taskState.latestData.filter(patient => {
        return patient.rpm && patient.rpm.lastData && patient.rpm.lastData && dayjs(patient.rpm.lastData).isToday()
      })
    })

    const notesForProviderReview = computed(() => {
      let notifications = []
      taskState.providerReviewNotifications.forEach(notification => {
        let patient = taskState.patientDict[notification.patient]

        if (patient) {
          let notificationObject = {}

          notificationObject.firstName = patient ? patient.firstName : 'Missing'
          notificationObject.lastName = patient ? patient.lastName : 'Missing'
          notificationObject.org = { patientId: patient ? patient.org.patientId : 'Missing' }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = generateNoteForDisplay(patient, notification)

          notifications.push(notificationObject)
        } else {
          console.log('not a patient:', notification.patient)
        }
      })
      return notifications
    })

    const toggleStarred = async patient => {
      const patientInArray = taskState.latestData.find(pt => pt.id === patient.id)
      if (patientInArray.tags.includes('Practice Starred')) {
        patientInArray.tags = patientInArray.tags.filter(e => e !== 'Practice Starred')

        await updatePatient({ id: patientInArray.id, tags: { value: patientInArray.tags } })
      } else {
        patientInArray.tags.push('Practice Starred')

        await updatePatient({ id: patientInArray.id, tags: { value: patientInArray.tags } })
      }
    }

    const patientFromDevice = patient => {
      emit('set-patient', patient)
    }

    const navigateToPatient = patient => {
      // navigate using the EHR id
      if (patient.org && patient.org.patientId) {
        router.push({
          name: 'Patient',
          params: { id: patient.org.patientId },
        })
      } else {
        console.log('no org patientID')
      }
    }

    const navigateToPatientNotes = patient => {
      // navigate using the EHR id
      if (patient.org && patient.org.patientId) {
        router.push({
          name: 'Patient',
          params: { id: patient.org.patientId, tab: 1 },
        })
      } else {
        console.log('no org patientID')
      }
    }

    onMounted(async () => {
      await getPatientDict()
      organizationDevices.value = await getOrganizationDevices()
    })

    const orgUserDictionary = computed(() => {
      const dict = {}
      orgState.orgUsers.forEach(user => {
        dict[user.id] = { name: 'Unknown', initials: 'UK' }
        if (user.firstName && user.lastName) {
          dict[user.id].name = user.firstName + ' ' + user.lastName
          dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
        }
      })
      return dict
    })

    const generateNoteForDisplay = (patientObject, notification) => {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (orgUserDictionary.value[latestNote.userId]) {
          displayObject.author = orgUserDictionary.value[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = specificRelativeTimeWithHoursAndMinutes(notification.notificationTimestamp)
        return displayObject
      }

      return null
    }

    const legacyProviderReviewNoteForDisplay = patientObject => {
      if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Provider Review')) {
        let latestNote = patientObject.rpm.notes[0]
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (latestNote.comments.length > 0) {
          latestNote = latestNote.comments[0]
        }
        if (orgUserDictionary.value[latestNote.userId]) {
          displayObject.author = orgUserDictionary.value[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = dayjs().to(dayjs(latestNote.ts))
        return displayObject
      }

      return null
    }

    return {
      fetchingPatients,
      toggleStarred,
      patientFromDevice,
      taskState,
      organizationDevices,
      navigateToPatient,
      navigateToPatientNotes,
      activePatientsToday,
      orgUserDictionary,
      generateNoteForDisplay,
      notesForProviderReview,
      legacyProviderReviewNoteForDisplay,
      // legacyNotesForProviderReview,
    }
  },
})
</script>

<style scoped>
.novo-sticky-header {
  position: sticky;
  top: 109px;
  z-index: 1;
}
.sticky-header.no-header {
  top: 0;
}
</style>
