<template>
  <bottom-sheet-panel :padding="false" tooltip="Device List" :activatorIcon="'mdi-devices'" title="Device List">
    <v-row no-gutters class="px-4">
      <v-text-field
        background-color="grey lighten-3"
        solo
        flat
        prepend-inner-icon="mdi-magnify"
        placeholder="Device Search"
        v-model="deviceFilter"
      ></v-text-field>
    </v-row>
    <v-row v-if="filteredDevices.filter(e => !e.live).length > 0" class="mb-4 novo-sticky-header" no-gutters>
      <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
        <v-chip dark color="grey darken-1" small style="font-weight: bold">Unassigned Devices</v-chip>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-simple-table dense style="background-color: transparent" class="flex" tile>
        <template v-slot:default>
          <tbody>
            <tr v-for="(device, index) in filteredDevices.filter(e => !e.live)" :key="index">
              <td class="pa-0" style="height:50px; background-color: transparent">
                <v-row align="center" no-gutters>
                  <v-card flat color="transparent" class="px-2" height="50">
                    <v-row no-gutters class="fill-height px-2" justify="center" align="center">
                      <v-img
                        v-if="deviceKindDictionary[device.kind]"
                        max-width="26"
                        max-height="26"
                        contain
                        :src="deviceKindDictionary[device.kind].image"
                      />
                    </v-row>
                  </v-card>
                  <v-col>
                    <div style="max-width: 70px; text-align: left;">
                      <span class="text-truncate">{{ device.kind }}</span>
                    </div>
                    <v-row align="center" no-gutters style="text-align: left; font-size: 10px;">
                      <div style="height: 24px" class="mr-1">
                        <v-row align="center" no-gutters class="fill-height">
                          <span>{{ device.deviceRefId }}</span>
                        </v-row>
                      </div>
                      <v-btn
                        @click="$emit('patient-click', patientDictionary[device.patientId])"
                        v-if="patientDictionary[device.patientId] && device.live"
                        x-small
                        text
                        color="primary"
                        class="py-0"
                        >{{ patientDictionary[device.patientId].firstName.charAt(0) }}.
                        {{ patientDictionary[device.patientId].lastName }}</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-row class="mb-4 novo-sticky-header" no-gutters>
      <v-card tile flat class="py-2 flex px-4 " color="#ffffffe6" style="backdrop-filter: blur(2px);">
        <v-chip dark color="grey darken-1" small style="font-weight: bold">Assigned Devices</v-chip>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-simple-table dense style="background-color: transparent" class="flex" tile>
        <template v-slot:default>
          <tbody>
            <tr v-for="(device, index) in filteredDevices.filter(e => e.live)" :key="index">
              <td class="pa-0" style="height:50px; background-color: transparent">
                <v-row align="center" no-gutters>
                  <v-card flat color="transparent" class="px-2" height="50">
                    <v-row no-gutters class="fill-height px-2" justify="center" align="center">
                      <v-img
                        v-if="deviceKindDictionary[device.kind]"
                        max-width="26"
                        max-height="26"
                        contain
                        :src="deviceKindDictionary[device.kind].image"
                      />
                    </v-row>
                  </v-card>
                  <v-col>
                    <div style="max-width: 70px; text-align: left;">
                      <span class="text-truncate">{{ device.kind }}</span>
                    </div>
                    <v-row align="center" no-gutters style="text-align: left; font-size: 10px;">
                      <div style="height: 24px" class="mr-1">
                        <v-row align="center" no-gutters class="fill-height">
                          <span>{{ device.deviceRefId }}</span>
                        </v-row>
                      </div>
                      <v-btn
                        @click="$emit('patient-click', patientDictionary[device.patientId])"
                        v-if="patientDictionary[device.patientId] && device.live"
                        x-small
                        text
                        color="primary"
                        class="py-0"
                        >{{ patientDictionary[device.patientId].firstName.charAt(0) }}.
                        {{ patientDictionary[device.patientId].lastName }}</v-btn
                      ><span v-else>{{ patientDictionary[device.patientId] }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </bottom-sheet-panel>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import BottomSheetPanel from './BottomSheetPanel.vue'
import EnrollmentFlowModalView from './enrollment/EnrollmentFlowModalView.vue'
import { DEVICES } from '@/consts'
import EmptyPage from './EmptyPage.vue'
import { unassignDevice } from '@/shared-ui/store/rpm'

export default defineComponent({
  components: { BottomSheetPanel, EnrollmentFlowModalView, EmptyPage },
  props: {
    enrolledPatients: {
      type: Array,
      default: null,
    },
    devices: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup(props, { emit }) {
    onMounted(() => {})
    const deviceFilter = ref(null)

    const deviceKindDictionary = computed(() => {
      const dict = {}
      DEVICES.forEach(device => {
        dict[device.kind] = { image: device.image, title: device.name, desc: device.description }
      })
      return dict
    })

    const patientDictionary = computed(() => {
      let dict = {}
      if (props.enrolledPatients) {
        props.enrolledPatients.forEach(patient => {
          dict[patient.id] = patient
        })
      }
      return dict
    })

    const removeDeviceFromPatient = async device => {
      await unassignDevice(device.id)
      emit('refreshDeviceList')
    }

    const filteredDevices = computed(() => {
      if (deviceFilter.value === null || deviceFilter.value === '') {
        return props.devices
      } else {
        return props.devices.filter(device => {
          return (
            (device.patientId &&
              patientDictionary.value[device.patientId] &&
              patientDictionary.value[device.patientId].lastName
                .toLowerCase()
                .includes(deviceFilter.value.toLowerCase())) ||
            device.refId.includes(deviceFilter.value)
          )
        })
      }
    })

    return {
      close,
      props,
      deviceKindDictionary,
      removeDeviceFromPatient,
      patientDictionary,
      filteredDevices,
      deviceFilter,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
