import dayjs from 'dayjs'
import { timeAgo } from '@/shared-ui/helpers/data.js'

export const daysPast = days => {
  const inThePast = new Date()
  inThePast.setDate(new Date().getDate() - days) // 30 days
  return inThePast
}

export function msToMinutesAndSeconds(ms) {
  if (!ms) return '00:00'
  let seconds = Math.floor((ms / 1000) % 60)
  let minutes = Math.floor((ms / (1000 * 60)) % 60)
  let hours = Math.floor(ms / (1000 * 60 * 60))

  minutes = minutes + hours * 60

  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return minutes + ':' + seconds
}

export function specificRelativeTimeWithHoursAndMinutes(rawValue) {
  // if it is today then a minutes/hours ago is fine.
  //if it is yesterday, then yesterday at 459pm is fine
  //if it is before then, the date atnd time
  let value = rawValue
  if (!Number.isInteger(rawValue)) {
    value = Date.parse(rawValue)
  }
  if (value) {
    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)
    let todayCutoff = startOfToday.getTime()

    let startOfYesterday = new Date()
    startOfYesterday.setDate(startOfYesterday.getDate() - 1)
    startOfYesterday.setHours(0, 0, 0, 0)
    let yesterdayCutoff = startOfYesterday.getTime()

    if (new Date().getTime() - value < 60 * 40 * 1000) {
      return timeAgo(new Date().getTime() - value)
    }

    if (value > todayCutoff) {
      return 'Today at ' + dayjs(value).format('h:mm a')
    }

    if (value > yesterdayCutoff) {
      return 'Yesterday at ' + dayjs(value).format('h:mm a')
    }
    return dayjs(value).format('M/D/YYYY h:mm a')
  }
  return '-'
}
