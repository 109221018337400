export const DEVICE_TYPES = [
  'UNKNOWN',
  'BODYTRACE',
  'CAREMATIX',
  'FITBIT',
  'GARMIN',
  'GOOGLEFIT',
  'IGLUCOSE',
  'IHEALTH',
  'LOHMAN',
  'OMRON',
  'WITHINGS',
]
export const READING_TYPES = {
  MEASUREMENT_BLOOD_GLUCOSE: 'MEASUREMENT_BLOOD_GLUCOSE',
  MEASUREMENT_BLOOD_OXYGEN: 'MEASUREMENT_BLOOD_OXYGEN',
  MEASUREMENT_BLOOD_PRESSURE: 'MEASUREMENT_BLOOD_PRESSURE',
  MEASUREMENT_BODY_HEIGHT: 'MEASUREMENT_BODY_HEIGHT',
  MEASUREMENT_BODY_TEMPERATURE: 'MEASUREMENT_BODY_TEMPERATURE',
  MEASUREMENT_BODY_WEIGHT: 'MEASUREMENT_BODY_WEIGHT',
  MEASUREMENT_PAIN: 'MEASUREMENT_PAIN',
  MEASUREMENT_PULSE: 'MEASUREMENT_PULSE',
  MEASUREMENT_ECG: 'MEASUREMENT_ECG',
}
