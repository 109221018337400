var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"logoSpace"}},[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[_c('v-img',{attrs:{"width":"100","src":require('@/assets/ArrowSmallLogo.png')}})],1)],1),_c('portal',{attrs:{"to":"toolbarItems"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('taskboard-settings-manager'),(_vm.organizationDevices)?_c('org-device-manager',{attrs:{"enrolledPatients":_vm.taskState.latestData,"devices":_vm.organizationDevices},on:{"patient-click":_vm.patientFromDevice}}):_vm._e(),(false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('refreshPatientList')}}},[(!_vm.refreshingPatientList)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")]):_vm._e(),(_vm.refreshingPatientList)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"18","width":"2"}}):_vm._e()],1)]}}],null,false,274384729)},[_vm._v(" Refresh ")]):_vm._e()],1)],1),(_vm.taskState.providerReviewNotifications.length > 0)?_c('v-row',{staticClass:"my-4 novo-sticky-header",attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"py-2 flex px-4 ",staticStyle:{"backdrop-filter":"blur(2px)"},attrs:{"tile":"","flat":"","color":"#ffffffe6"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":"primary","small":""}},[_vm._v("PROVIDER REVIEW")]),_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm.notesForProviderReview.length))])],1)],1)],1):_vm._e(),_c('v-expand-transition',[(_vm.taskState.providerReviewNotifications.length > 0)?_c('div',{staticClass:"mb-10"},_vm._l((_vm.notesForProviderReview),function(note,index){return _c('note-row',{key:index + 'notifications',attrs:{"patient":note,"color":"newAlert","lastOne":index === _vm.notesForProviderReview.length - 1},on:{"toggleStarred":_vm.toggleStarred,"patient-click":function($event){return _vm.navigateToPatientNotes(note)}}})}),1):_vm._e()]),_c('v-slide-y-reverse-transition',[(_vm.taskState.latestData && _vm.taskState.latestData.length > 0)?_c('div',[(
          _vm.taskState.latestData.filter(function (patient) {
            return patient.tags.includes('Practice Starred')
          }).length > 0
        )?_c('div',{staticClass:"mb-10"},[_c('v-row',{staticClass:"my-4 novo-sticky-header",attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"py-2 flex px-4 ",staticStyle:{"backdrop-filter":"blur(2px)"},attrs:{"tile":"","flat":"","color":"#ffffffe6"}},[_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":"grey lighten-2","small":""}},[_vm._v("STARRED")])],1)],1),_vm._l((_vm.taskState.latestData.filter(function (patient) {
            return patient.tags.includes('Practice Starred')
          })),function(patient,index){return _c('v-lazy',{directives:[{name:"show",rawName:"v-show",value:(patient.tags.includes('Practice Starred')),expression:"patient.tags.includes('Practice Starred')"}],key:index + 'starred',attrs:{"options":{
            threshold: 0,
          },"min-height":"65","transition":null}},[_c('patient-row',{attrs:{"patient":patient,"color":"newAlert","lastOne":index ===
                _vm.taskState.latestData.filter(function (patient) {
                  return patient.tags.includes('Practice Starred')
                }).length -
                  1},on:{"toggleStarred":_vm.toggleStarred,"patient-click":function($event){return _vm.navigateToPatient(patient)}}})],1)})],2):_vm._e(),(
          _vm.taskState.latestData.filter(function (patient) {
            return patient.rpm.newAlerts
          }).length > 0
        )?_c('div',{staticClass:"mb-10"},[_c('v-row',{staticClass:"my-4 novo-sticky-header",attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"py-2 flex px-4 ",staticStyle:{"backdrop-filter":"blur(2px)"},attrs:{"tile":"","flat":"","color":"#ffffffe6"}},[_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":"grey lighten-2","small":""}},[_vm._v("RECENT ALERTS")])],1)],1),_vm._l((_vm.taskState.latestData.filter(function (patient) {
            return patient.rpm.newAlerts
          })),function(patient,index){return _c('v-lazy',{directives:[{name:"show",rawName:"v-show",value:(patient.rpm.newAlerts),expression:"patient.rpm.newAlerts"}],key:index + 'newalert',attrs:{"options":{
            threshold: 0,
          },"min-height":"65","transition":null}},[_c('patient-row',{attrs:{"patient":patient,"color":"newAlert","alertType":true,"lastOne":index ===
                _vm.taskState.latestData.filter(function (patient) {
                  return patient.rpm.newAlerts
                }).length -
                  1},on:{"toggleStarred":_vm.toggleStarred,"patient-click":function($event){return _vm.navigateToPatient(patient)}}})],1)})],2):_vm._e(),_c('v-row',{staticClass:"mb-4 novo-sticky-header",attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"py-2 flex px-4 ",staticStyle:{"backdrop-filter":"blur(2px)"},attrs:{"tile":"","flat":"","color":"#ffffffe6"}},[_c('v-chip',{staticStyle:{"font-weight":"bold"},attrs:{"color":"grey lighten-2","small":""}},[_vm._v("LATEST READINGS")])],1)],1),_vm._l((_vm.taskState.latestData),function(patient,index){return _c('v-lazy',{key:index + 'latest',attrs:{"options":{
          threshold: 0,
        },"min-height":"65","transition":null}},[_c('patient-row',{attrs:{"patient":patient,"color":"newAlert"},on:{"toggleStarred":_vm.toggleStarred,"patient-click":function($event){return _vm.navigateToPatient(patient)}}})],1)})],2):_vm._e()]),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.taskState.latestData && _vm.taskState.latestData.length === 0 && !_vm.taskState.refreshingData)?_c('div',[_c('empty-page',{attrs:{"title":"No tasks at the moment","detail":"Data from each patient with a connected device, as well as any patients that need your attention will appear here.","image":require('@/assets/taskboard.svg')}},[_c('v-btn',{attrs:{"color":_vm.refreshingPatientList ? 'grey lighten-3' : 'primary',"depressed":"","rounded":""},on:{"click":_vm.refreshingPatientList}},[_vm._v("Refresh")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }