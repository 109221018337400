<template>
  <v-scale-transition>
    <bottom-sheet-panel
      v-if="unacknowledgedAlerts"
      :openOnCreate="unacknowledgedAlerts"
      :badge="unacknowledgedAlerts"
      tooltip="Alerts"
      :activatorIcon="'mdi-alert-circle-outline'"
      title="Patient Notifications"
    >
      <!-- <patient-note-card
        class="mb-4"
        v-for="(note, index) in notesNeedingReview"
        :key="index"
        :note="note"
        :patient="patient"
        :userDict="orgUserDictionary"
      /> -->
      <v-row no-gutters>
        <v-card v-if="newAlerts.length === 0" color="grey lighten-4" flat height="100" class="flex">
          <v-row no-gutters align="center" justify="center" class="fill-height text--secondary">
            {{ informationalStatement }}
          </v-row>
        </v-card>

        <v-card v-else flat class="flex pb-4" color="transparent">
          <v-card-text class="pa-0 pb-2">{{ informationalStatement }}</v-card-text>

          <v-row class="py-2" align="center" no-gutters v-for="(alert, index) in newAlerts" :key="index">
            <patient-alert-notification-reading-display
              :reading="alert.event"
            ></patient-alert-notification-reading-display>
            <v-spacer />
            <v-btn
              :disabled="alert.selectedForAcknowledged"
              @click="acknowledgeAlert(alert)"
              small
              style="border-radius: 5px;"
              dark
              depressed
              color="blue darken-3"
            >
              <span v-show="!alert.selectedForAcknowledged">Acknowledge</span>
              <span v-show="alert.selectedForAcknowledged">Acknowledged</span>
            </v-btn>
            <!-- <v-btn
                  large
                  @click="alert.selectedForAcknowledged = !alert.selectedForAcknowledged"
                  color="blue darken-3"
                  icon
                  ><v-icon v-if="newAlerts[index].selectedForAcknowledged">mdi-check-circle</v-icon
                  ><v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon></v-btn
                > -->
          </v-row>
        </v-card>
      </v-row>
    </bottom-sheet-panel>
  </v-scale-transition>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { removePatientAlert } from '../shared-ui/store/rpm'
import dayjs from 'dayjs'
import { timeAgo } from '../shared-ui/helpers/data.js'
import BottomSheetPanel from './BottomSheetPanel.vue'
import PatientAlertNotificationReadingDisplay from './PatientAlertNotificationReadingDisplay.vue'
import { state as taskState } from '@/shared-ui/store/tasks'
import { state as orgState } from '@/shared-ui/store/org'
import PatientNoteCard from '@/components/patientchart/PatientNoteCard.vue'

export default defineComponent({
  components: { BottomSheetPanel, PatientAlertNotificationReadingDisplay, PatientNoteCard },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    devices: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup(props) {
    const unacknowledgedAlerts = ref(false)

    const showAll = ref(false)
    const patientFullName = ref('')
    const patientAge = ref('')
    const patientGender = ref('')
    const orgPatientId = ref('')
    const time = ref(null)
    const latestAlertsArray = ref([])
    const newAlerts = ref([])
    const showAlertCard = ref(false)

    const notesNeedingReview = computed(() => {
      let theNotesToReturn = []
      taskState.providerReviewNotifications.forEach(notification => {
        let notificationID = notification.patient
        let patientID = props.patient.id
        if (notificationID === patientID) {
          props.patient.rpm.notes.forEach(note => {
            let noteTime = note.ts
            let notificationTime = notification.ts
            if (noteTime === notificationTime) {
              theNotesToReturn.push(note)
            }
          })
        }
      })
      return theNotesToReturn
    })

    const orgUserDictionary = computed(() => {
      const dict = {}
      orgState.orgUsers.forEach(user => {
        dict[user.id] = { name: 'Unknown', initials: 'UK' }
        if (user.firstName && user.lastName) {
          dict[user.id].name = user.firstName + ' ' + user.lastName
          dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
        }
      })
      return dict
    })

    const typeMapping = {
      MEASUREMENT_BLOOD_GLUCOSE: 'Blood Glucose',
      MEASUREMENT_BLOOD_PRESSURE: 'Blood Pressure',
      MEASUREMENT_PULSE: 'Heart Rate',
      MEASUREMENT_ECG: 'ECG',
      BoodGlucose: 'Blood Glucose',
      BloodPressure: 'Blood Pressure',
      Pulse: 'Heart Rate',
      Ecg: 'ECG',
    }

    const goToChart = () => {
      if (orgPatientId) {
        const url = new URL(location.href)
        url.searchParams.set('patient', orgPatientId.value)
        location.href = url.href
      }
    }

    const close = () => {
      showAlertCard.value = false
    }

    const acknowledgeAlert = async fullEvent => {
      console.log(fullEvent)
      let patientEventId = fullEvent.event.id
      try {
        await removePatientAlert(patientEventId)
        fullEvent.selectedForAcknowledged = true

        // check to see if everything is acknowledged
        let shouldClose = true
        newAlerts.value.forEach(alert => {
          if (!alert.selectedForAcknowledged) {
            shouldClose = false
          }
        })
        if (shouldClose) {
          unacknowledgedAlerts.value = false
          setTimeout(function() {
            showAlertCard.value = false
          }, 300)
        }
      } catch (e) {
        console.log(e)
      }
    }

    const informationalStatement = computed(() => {
      if (props.patient.rpm && props.patient.rpm.newAlertsArray && props.patient.rpm.newAlertsArray.length > 0) {
        let statement = props.patient.firstName + "'s most recent "
        let commonMetrics = []
        props.patient.rpm.newAlertsArray.forEach(metric => {
          commonMetrics.push(typeMapping[metric])
        })

        var outStr = ''
        if (commonMetrics.length === 1) {
          outStr = commonMetrics[0]
        } else if (commonMetrics.length === 2) {
          outStr = commonMetrics.join(' and ')
        } else if (commonMetrics.length > 2) {
          outStr = commonMetrics.slice(0, -1).join(', ') + ', and ' + commonMetrics.slice(-1)
        }

        let join = ' measurement is '

        if (commonMetrics.length > 1) {
          join = ' measurements are '
        }

        statement = statement + ' ' + outStr + join + 'out of range.'

        return statement
      }
      return 'No vitals are out of range'
    })

    onMounted(() => {
      patientFullName.value = props.patient.firstName + ' ' + props.patient.lastName
      if (props.patient.birthdate) {
        patientAge.value = dayjs().diff(props.patient.birthdate, 'years')
      }
      orgPatientId.value = props.patient.org?.patientId
      patientGender.value = props.patient.gender?.charAt(0)
      time.value = timeAgo(dayjs().diff(props.patient.rpm?.lastAlert, 'milliseconds'))

      // find the latest alert event in latestEvents by the key in the newAlertsArray
      props.patient.rpm?.newAlertsArray.forEach(alert => {
        latestAlertsArray.value.push(props.patient.latestEvents[alert])
      })
      // if still not alerts then use the lastAlert timestamp
      if (!latestAlertsArray.value.length) {
        Object.keys(props.patient.latestEvents).forEach(key => {
          if (props.patient.latestEvents[key].timestamp === props.patient.rpm.lastAlert) {
            latestAlertsArray.value.push(props.patient.latestEvents[key])
          }
        })
      }

      if (props.patient.rpm && props.patient.rpm.newAlertsArray) {
        let listOfAcknowledgementObjects = []

        props.patient.rpm.newAlertsArray.forEach(metric => {
          let object = {}
          object.event = props.patient.latestEvents[metric]
          object.selectedForAcknowledged = false
          object.commonName = typeMapping[metric]
          object.eventKind = metric
          listOfAcknowledgementObjects.push(object)
        })
        newAlerts.value = listOfAcknowledgementObjects
      }
      setTimeout(function() {
        if (props.patient.rpm.newAlertsArray.length > 0) {
          unacknowledgedAlerts.value = true
        }
      }, 500)
    })

    return {
      close,
      goToChart,
      latestAlertsArray,
      orgPatientId,
      patientAge,
      patientFullName,
      patientGender,
      showAll,
      time,
      newAlerts,
      informationalStatement,
      showAlertCard,
      acknowledgeAlert,
      unacknowledgedAlerts,
      notesNeedingReview,
      orgUserDictionary,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
