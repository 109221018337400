<template>
  <v-container v-if="rpmState && rpmState.patient" class="pa-0" fluid>
    <portal to="toolbarItems">
      <patient-notification-manager v-if="ready" :patient="props.patient" />
      <patient-export-manager :patient="props.patient" v-if="ready" />
      <patient-device-manager
        v-if="ready"
        @addDevice="$emit('addDevice')"
        :devices="patientDeviceList"
        :patient="props.patient"
        @refreshDeviceList="getPatientDeviceList"
      />

      <patient-compliance-manager v-if="ready && false" :patient="props.patient" :activity="allEvents" />
      <patient-tag-manager v-if="ready && false" :patient="props.patient" />

      <chart-timer :patient="props.patient" />
    </portal>
    <portal to="tagSection">
      <v-btn large class="mr-2" @click.stop.prevent="toggleStarred()" icon>
        <v-icon color="#F7CB4E" v-if="props.patient.tags.includes('Practice Starred')">mdi-star</v-icon>
        <v-icon color="grey lighten-2" v-else>mdi-star-outline</v-icon>
      </v-btn>

      <!-- <v-btn large class="mr-2" @click.stop.prevent="toggleProviderReview()" icon>
        <v-icon color="red" v-if="props.patient.tags.includes('Provider Review')">mdi-flag-variant</v-icon>
        <v-icon color="grey lighten-2" v-else>mdi-flag-variant-outline</v-icon>
      </v-btn> -->
    </portal>
    <portal to="toolbarTabs">
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab>
          Data
        </v-tab>
        <v-tab>
          Notes
        </v-tab>
        <v-tab>
          Messages
        </v-tab>
      </v-tabs>
    </portal>

    <v-tabs-items class="elevation-0" v-model="activeTab">
      <v-tab-item class="pt-4 ">
        <v-row no-gutters>
          <v-col v-if="rpmState.patient.rpm.lastData" cols="12">
            <!-- <v-fade-transition>
              <v-row v-if="chartPanels && chartPanels.length > 0" no-gutters justify="center">
                <v-btn
                  rounded
                  v-for="(panel, index) in chartPanels"
                  :key="index + 'panelButton'"
                  @click="selectedPanel = index"
                  text
                  :color="selectedPanel === index ? 'primary' : 'grey lighten-1'"
                  :icon="index === 0"
                >
                  <v-icon size="16" v-if="index === 0">mdi-poll</v-icon>
                  <span v-if="panel.abbreviation">{{ panel.abbreviation }}</span></v-btn
                >
              </v-row>
            </v-fade-transition> -->
            <v-slide-x-reverse-transition>
              <v-slide-group
                v-if="rpmState.patient && chartPanels && chartPanels.length > 0"
                mandatory
                center-active
                :show-arrows="false"
                v-model="selectedPanel"
              >
                <v-slide-item
                  v-slot="{ active, toggle }"
                  class="mr-3 mb-7 mt-1"
                  :class="index == 0 ? 'mx-3' : ''"
                  v-for="(panel, index) in chartPanels"
                  :key="index + 'panel'"
                >
                  <div v-if="panel.commonName === 'graph'" class="card-shadow">
                    <v-card
                      :dark="active"
                      :color="active ? 'primary' : 'grey lighten-4'"
                      @click="toggle"
                      class="pa-3"
                      flat
                      min-width="100"
                      rounded="lg"
                      height="83"
                    >
                      <v-row
                        justify="center"
                        class="text-caption font-weight-medium fill-height"
                        align="center"
                        no-gutters
                        ><div>
                          <v-row no-gutters justify="center">
                            <v-icon size="30">mdi-poll</v-icon>
                          </v-row>
                          <v-row no-gutters justify="center">
                            <div>Flowsheet</div>
                          </v-row>
                        </div>
                      </v-row>
                    </v-card>
                  </div>
                  <div class="card-shadow" v-else>
                    <v-card
                      :dark="active"
                      :color="active ? 'primary' : 'grey lighten-4'"
                      @click="toggle"
                      class="pa-3"
                      flat
                      min-width="100"
                      rounded="lg"
                      height="83"
                    >
                      <v-card
                        :color="panel.alert ? 'yellow' : 'grey lighten-1'"
                        flat
                        style="position: absolute; left: 10px; top: 8px; height: calc(100% - 16px); border-radius: 5px;"
                        width="10"
                      >
                      </v-card>
                      <v-row justify="start" class="text-caption font-weight-medium ml-5" no-gutters
                        ><v-icon class="mr-1" size="14">{{ panel.icon }}</v-icon
                        >{{ panel.title }}
                      </v-row>
                      <v-row class="text-body-1 font-weight-medium ml-5" align="end" justify="start" no-gutters
                        >{{ panel.data
                        }}<span class="text-caption ml-1" style="padding-bottom:1px">{{ panel.units }}</span>
                      </v-row>

                      <v-row justify="start" class="text-caption ml-5" style="margin-top:-3px" no-gutters
                        >{{ panel.time }}
                      </v-row>
                    </v-card>
                  </div>
                </v-slide-item>
              </v-slide-group>
            </v-slide-x-reverse-transition>
            <v-slide-y-transition>
              <v-card v-if="ready" flat class="novo-sticky-header" min-height="300">
                <v-tabs-items v-model="selectedPanel">
                  <v-tab-item>
                    <v-card flat>
                      <v-card height="55" flat tile>
                        <v-row class="px-4 fill-height" align="center" justify="end" no-gutters>
                          <!-- <v-btn small text outlined depressed v-if="zoomed" disabled>{{ filteredDays }} day</v-btn> -->

                          <div class="font-weight-light  text-h6">{{ filteredTime }}</div>
                          <v-spacer></v-spacer>

                          <v-btn @click="zoomto90" class="mr-1" depressed small rounded>90d</v-btn>
                          <v-btn @click="zoomto30" class="mr-1" depressed small rounded>30d</v-btn>
                          <v-btn @click="zoomto7" depressed small rounded>7d</v-btn>
                        </v-row>
                      </v-card>
                      <v-row style="position: absolute; width: 100%; top: 55px; z-index:1000" class="px-3" no-gutters>
                        <v-row justify="end" align="center" no-gutters>
                          <v-btn icon @click="zoom('in')">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>

                          <v-btn icon @click="zoom()">
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <v-btn icon :disabled="disableMoveButtons.back" @click="moveBack">
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>

                          <v-btn icon :disabled="disableMoveButtons.forward" @click="moveForward">
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-row>
                      </v-row>

                      <v-card class="pt-3 pr-4" flat tile>
                        <vue-apex-charts
                          ref="chart"
                          light
                          type="line"
                          :options="chartOptions"
                          :series="chartData.data"
                          @dblclick.native="zoomed = false"
                          height="230"
                        ></vue-apex-charts>
                      </v-card>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item v-for="(reading, index) in sortedLatestEvents" :key="index + 'reading2'">
                    <v-card flat class="px-4">
                      <reading-summary
                        :kind="reading.kind"
                        :readings="rpmState.eventsByKind[reading.kind]"
                        :days="filteredDays"
                        @get-pdf="showPDF"
                      ></reading-summary>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <v-divider />
              </v-card>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <flowsheet
                v-if="ready"
                ref="flowsheet"
                :tableHeaders="tableHeaders"
                :allEvents="allEvents"
                :focusMetric="focusMetric"
                :selectedPanel="selectedPanel"
                :columnArray="chartPanels"
                :start="start"
                :end="end"
                @showPDF="showPDF"
                @updateFocus="updateFocus"
              />
            </v-slide-y-transition>
          </v-col>
          <v-col v-else-if="rpmState.patient.rpm && !rpmState.patient.rpm.lastData">
            <empty-page
              title="No data yet"
              detail="Data from each connected device will appear here in real time. "
              :image="require('@/assets/nodata.svg')"
              v-if="patient.rpm.notes.length === 0"
            >
              <v-btn :color="refreshingChart ? 'grey lighten-3' : 'primary'" @click="refreshChart" depressed rounded
                >Refresh Chart</v-btn
              >
            </empty-page>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pt-4 ">
        <patient-notes :patient="patient" />
      </v-tab-item>
      <v-tab-item>
        <patient-conversation :patient="patient" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { watch, computed, defineComponent, onBeforeMount, onMounted, ref } from '@vue/composition-api'
import { PDFDocument } from 'pdf-lib'
import { READING_TYPES } from '../../shared-ui/store/consts/devices'
import { getPatient, getPatientDevices, getPatientEvents, state as rpmState } from '../../shared-ui/store/rpm'
import ReadingSummary from '../ReadingSummary.vue'
import { daysPast } from '../../helpers/time.js'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import CONSTS from '../../consts'
import VueApexCharts from 'vue-apexcharts'
import PatientExportManager from '../PatientExportManager.vue'
import PatientDeviceManager from '../PatientDeviceManager.vue'
import PatientNotificationManager from '../PatientNotificationManager.vue'
import { evaluatePatient, normalizeValue } from '../../nurse-sidekick'
import Flowsheet from '../Flowsheet.vue'
import PatientComplianceManager from '../PatientComplianceManager.vue'
import PatientTagManager from '../PatientTagManager.vue'
import { updatePatient } from '../../shared-ui/store/patient'
import PatientNotes from './PatientNotes.vue'
import EmptyPage from '../EmptyPage.vue'
import PatientConversation from './PatientConversation.vue'
import ChartTimer from './ChartTimer.vue'
import { lastBridgeAccess } from '@/shared-ui/store/tasks'
import { state as ssoState } from '@/shared-ui/store/sso'

dayjs.extend(isBetween)
dayjs.extend(relativeTime)

export default defineComponent({
  components: {
    ReadingSummary,
    VueApexCharts,
    PatientExportManager,
    PatientDeviceManager,
    PatientNotificationManager,
    Flowsheet,
    PatientComplianceManager,
    PatientTagManager,
    PatientNotes,
    EmptyPage,
    PatientConversation,
    ChartTimer,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    chartTab: 0,
    timePeriod: {
      type: Object,
      default: () => {
        return {
          end: new Date(),
          start: daysPast(182),
        }
      },
    },
  },
  setup(props) {
    const chartPanels = ref([])
    const selectedPanel = ref(0)
    const activeTab = ref(0)
    const chart = ref(null)
    const start = ref(null)
    const end = ref(null)
    const tab = ref(0)
    const ready = ref(false)
    const timeframe = ref(7)
    const eventsByReading = ref({})
    const allEvents = ref([])
    const tableHeaders = ref([])
    const zoomed = ref(false)
    const supportedPatientsEvents = ref([])
    const patientDeviceList = ref([])
    const selectedMetric = ref(0)
    const showPreviousMetric = ref(false)
    const showNextMetric = ref(true)
    const preparingChart = ref(false)
    const refreshingChart = ref(false)

    const sortedLatestEvents = computed(() => {
      if (props.patient && props.patient.eventsArray) {
        return props.patient.eventsArray.sort(function(a, b) {
          return a.order - b.order
        })
      }
      return []
    })

    watch(
      () => props.patient,
      () => {
        getPatientDeviceList()
      }
    )

    const flowsheet = ref(null)

    watch(selectedPanel, selectedPanel => {
      flowsheet.value.zoomTo(selectedPanel)
    })

    const focusMetric = computed(() => {
      if (selectedMetric.value > 0) {
        if (sortedLatestEvents.value[selectedMetric.value - 1].kind) {
          return sortedLatestEvents.value[selectedMetric.value - 1]
        }
      }
      return null
    })

    const slideTabs = ref(null)

    const updateFocus = index => {
      selectedPanel.value = index
    }

    const getPatientDeviceList = async () => {
      let devices = await getPatientDevices(props.patient.id)
      patientDeviceList.value = devices
    }

    const firstOptionVisible = entries => {
      if (!entries[0].isIntersecting) {
        showPreviousMetric.value = true
      } else {
        showPreviousMetric.value = false
      }
    }

    const lastOptionVisible = entries => {
      if (!entries[0].isIntersecting) {
        showNextMetric.value = true
      } else {
        showNextMetric.value = false
      }
    }

    const slideTabsNext = () => {
      if (selectedMetric.value + 1 >= sortedLatestEvents.value.length) {
        selectedMetric.value = sortedLatestEvents.length
      } else {
        selectedMetric.value = selectedMetric.value + 1
      }
    }

    const slideTabsPrev = () => {
      if (selectedMetric.value - 1 <= 0) {
        selectedMetric.value = 0
      } else {
        selectedMetric.value = selectedMetric.value - 1
      }
    }

    const formatDate = dt => {
      return dayjs(dt).format('M/D/YY')
    }

    const formatTime = dt => {
      return dayjs(dt).format('h:mm A')
    }

    const filteredTime = computed(() => {
      return dayjs(start.value).format('MMM DD') + ' - ' + dayjs(end.value).format('MMM DD')
    })
    const switchTimeframe = days => {
      zoomed.value = false
      timeframe.value = days
    }
    const getChartEvents = async () => {
      let alerts = []
      let eventsByType = {}
      ready.value = false
      preparingChart.value = false
      // setBadgeCount(0)
      allEvents.value = []
      tableHeaders.value = []
      await Promise.all(
        supportedTypes.map(async eventName => {
          let event = typeMapping[eventName]
          let events = await getPatientEvents(props.patient.id, event, props.timePeriod.start, props.timePeriod.end)

          if (events.length) {
            if (event === 'MEASUREMENT_BLOOD_PRESSURE') {
              eventsByType['SystolicPressure'] = []
              eventsByType['DiastolicPressure'] = []
              events = events.map(e => {
                e.data[CONSTS.supportedMetrics[eventName].abbreviation] =
                  parseInt(e.data.systolicValue) + '/' + parseInt(e.data.diastolicValue)
                eventsByType['SystolicPressure'].push({
                  alert: e.alert,
                  timestamp: e.timestamp,
                  data: { value: e.data.systolicValue },
                })
                eventsByType['DiastolicPressure'].push({
                  alert: e.alert,
                  timestamp: e.timestamp,
                  data: { value: e.data.diastolicValue },
                })
                if (e.alert) {
                  // appletBroadcastSnackbar({
                  //   text: props.patient.firstName + ' has a reading out of range: ' + typeMapping[event],
                  // })
                  alerts.push(e)
                }
                return e
              })
            } else if (event === 'MEASUREMENT_ECG') {
              eventsByType[typeMapping[event]] = []
              events = events.map(e => {
                if (e.data.afib) {
                  e.data[CONSTS.supportedMetrics[eventName].abbreviation] = 'AFIB'
                } else {
                  e.data[CONSTS.supportedMetrics[eventName].abbreviation] = 'No AFIB'
                }
                if (e.alert) {
                  // appletBroadcastSnackbar({
                  //   text: props.patient.firstName + ' has a reading out of range: ' + typeMapping[event],
                  // })
                  alerts.push(e)
                }
                return e
              })
            } else {
              eventsByType[typeMapping[event]] = []
              events = events.map(e => {
                e.data[CONSTS.supportedMetrics[eventName].abbreviation] = normalizeValue(e)
                eventsByType[typeMapping[event]].push({
                  alert: e.alert,
                  timestamp: e.timestamp,
                  data: { value: normalizeValue(e) },
                })
                if (e.alert) {
                  // appletBroadcastSnackbar({
                  //   text: props.patient.firstName + ' has a reading out of range: ' + typeMapping[event],
                  // })
                  alerts.push(e)
                }
                return e
              })
            }
            allEvents.value = Object.values(
              [...allEvents.value, ...events].reduce((c, data) => {
                if (c[data.timestamp]) {
                  c[data.timestamp].data = {
                    ...c[data.timestamp].data,
                    ...data.data,
                  }
                } else {
                  c[data.timestamp] = data
                }
                return c
              }, {})
            ).sort((a, b) => {
              if (a.timestamp > b.timestamp) {
                return -1
              } else if (a.timestamp < b.timestamp) {
                return 1
              } else {
                return 0
              }
            })
            return
          }
        })
      )
      tableHeaders.value.sort(function(a, b) {
        return a.order - b.order
      })
      console.log('props right before', props.patient.eventsArray)
      supportedPatientsEvents.value = props.patient.eventsArray
        .filter(event => {
          return rpmState.eventsByKind[event.kind] && supportedTypes.findIndex(t => t === event) !== -1
        })
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1
          } else if (a.order > b.order) {
            return 1
          } else {
            return 0
          }
        })
      eventsByReading.value = eventsByType
      ready.value = true
      preparingChart.value = false
      refreshingChart.value = false
    }

    const chartColors = computed(() => {
      let done = {}
      const colors = []
      Object.keys(eventsByReading.value).map(key => {
        if (done[key]) {
          return
        }
        if (key === 'SystolicPressure' || key === 'DiastolicPressure') {
          colors.push(CONSTS.metricList[typeMapping['BloodPressure']].cardColor)
        } else {
          colors.push(CONSTS.metricList[typeMapping[key]].cardColor)
        }
        done[key] = true
      })
      return colors
    })

    const filteredEvents = computed(() => {
      if (zoomed.value) {
        return allEvents.value.filter(event => {
          return dayjs(event.timestamp).isBetween(start.value, end.value)
        })
      }
      return allEvents.value
    })

    const filteredEventsByType = computed(() => {
      let filteredObj = {}
      Object.keys(rpmState.eventsByKind).forEach(key => {
        filteredObj[key] = rpmState.eventsByKind[key].filter(event => event.kind === key)
      })
      return filteredObj
    })

    const filteredDays = computed(() => {
      if (zoomed.value) {
        return dayjs(end.value).diff(start.value, 'day')
      }
      return timeframe.value
    })
    // use const as cards are supported
    const supportedTypes = Object.keys(CONSTS.supportedMetrics)

    const typeMapping = {
      MEASUREMENT_BLOOD_GLUCOSE: 'BloodGlucose',
      MEASUREMENT_BLOOD_PRESSURE: 'BloodPressure',
      MEASUREMENT_PULSE: 'Pulse',
      MEASUREMENT_ECG: 'Ecg',
      MEASUREMENT_BODY_WEIGHT: 'BodyWeight',
      MEASUREMENT_BLOOD_OXYGEN: 'BloodOxygen',
      BloodOxygen: 'MEASUREMENT_BLOOD_OXYGEN',
      BloodGlucose: 'MEASUREMENT_BLOOD_GLUCOSE',
      BloodPressure: 'MEASUREMENT_BLOOD_PRESSURE',
      Pulse: 'MEASUREMENT_PULSE',
      Ecg: 'MEASUREMENT_ECG',
      BodyWeight: 'MEASUREMENT_BODY_WEIGHT',
      BodyTemp: 'MEASUREMENT_BODY_TEMPERATURE',
      Pain: 'MEASUREMENT_PAIN',
    }

    const typeMappingFullName = {
      MEASUREMENT_BLOOD_GLUCOSE: 'Blood Glucose',
      MEASUREMENT_BLOOD_PRESSURE: 'Blood Pressure',
      MEASUREMENT_PULSE: 'Heart Rate',
      MEASUREMENT_ECG: 'Ecg',
      MEASUREMENT_BLOOD_OXYGEN: 'Blood Oxygen',
      BloodGlucose: 'MEASUREMENT_BLOOD_GLUCOSE',
      BloodOxygen: 'MEASUREMENT_BLOOD_GLUCOSE',
      BloodPressure: 'MEASUREMENT_BLOOD_PRESSURE',
      Pulse: 'MEASUREMENT_PULSE',
      Ecg: 'MEASUREMENT_ECG',
    }

    const chartData = computed(() => {
      const discreteMarkers = []
      let data = Object.keys(eventsByReading.value).map((key, idx) => {
        return {
          name: key,
          type: 'line',
          data: eventsByReading.value[key].map((e, i) => {
            // create an array of discreteMarkers if alert
            if (e.alert) {
              discreteMarkers.push({
                seriesIndex: idx,
                dataPointIndex: i,
                fillColor: '#FFED3A',
                strokeColor: '#333333',
                size: 4,
                strokeWidth: 2,
                hover: {
                  sizeOffset: 4,
                },
              })
            }
            return {
              x: dayjs(e.timestamp),
              y: Math.round(e.data?.value && key !== 'Ecg' ? e.data.value : 0),
            }
          }),
        }
      })
      return { data, discreteMarkers }
    })

    const toggleSeries = series => {
      chart.value.hideSeries(series.name)
    }
    const chartOptions = computed(() => {
      if (!zoomed.value) {
        start.value = dayjs()
          .startOf('day')
          .subtract(timeframe.value, 'day')
          .valueOf()
        end.value = dayjs()
          .endOf('day')
          .endOf('day')
          .valueOf()
      }
      return {
        markers: {
          size: 4,
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: '#ffffff',
          hover: {
            sizeOffset: 4,
          },
          discrete: chartData.value.discreteMarkers,
        },
        theme: {
          dark: true,
          palette: 'palette4',
        },
        stroke: { curve: 'smooth', width: 2 },
        chart: {
          id: 'patient-chart',
          height: 230,
          width: '100%',
          events: {
            mounted: () => {
              // figure out if there are shown points and then adjust to find
            },
            zoomed: (chartContext, { xaxis }) => {
              start.value = xaxis.min
              end.value = xaxis.max
              zoomed.value = true
            },
          },
          toolbar: { show: false },
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: false,
          },
        },
        xaxis: {
          floating: false,
          type: 'datetime',
          max: end.value,
          min: start.value,
          crosshairs: { show: true, with: 20 },
          forceNiceScale: true,
          labels: {
            datetimeUTC: false,
            show: true,
            rotate: 0,
          },
          tooltip: { enabled: false },
          convertedCatToNumeric: false,
        },
        noData: {
          text: 'No Readings to show',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        colors: chartColors.value,
        legend: {
          show: false,
        },
      }
    })
    function getPdfUrl(byte) {
      var blob = new Blob([byte], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      return url
    }
    const showPDF = async metricData => {
      try {
        // fetch the pdf from the server
        let response = await fetch(metricData.pdfUrl)
        const pdf = await response.arrayBuffer()
        // load the pdf
        const pdfDoc = await PDFDocument.load(pdf)
        // add the patient for each page
        const pages = pdfDoc.getPages()
        const fontSize = 16
        const patientText = `${props.patient?.lastName.toUpperCase() || ''} ID:${props.patient?.org?.patientId ||
          'not found'}`
        pages.forEach(page => {
          const { width, height } = page.getSize()
          page.drawText(patientText, {
            x: width - patientText.length * (fontSize / 2) - 30,
            y: height - fontSize * 1.5,
            size: fontSize,
          })
        })
        const pdfBytes = await pdfDoc.save()
        const pdfUrl = getPdfUrl(pdfBytes)
        // open the new pdf with patient info
        window.open(pdfUrl, 'ECG', 'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300')
        //this.ecgDialog = true
      } catch (e) {
        // just open the provided url if something fails adding the patient info
        window.open(
          metricData.pdfUrl,
          'ECG',
          'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300'
        )
      }
    }
    const moveBack = () => {
      let range = dayjs().diff(dayjs().subtract(3, 'day'))
      chart.value.zoomX(start.value - range, end.value - range)
    }
    const zoom = dir => {
      let range = dayjs().diff(dayjs().subtract(3, 'day'))
      if (dir === 'in') {
        if (start.value + range - end.value < 0) {
          chart.value.zoomX(start.value + range, end.value)
        }
      } else {
        chart.value.zoomX(start.value - range, end.value)
      }
    }

    const zoomto7 = () => {
      let range = dayjs().diff(dayjs().subtract(7, 'day'))
      chart.value.zoomX(new Date().getTime() - range, new Date().getTime())
    }

    const zoomto30 = () => {
      let range = dayjs().diff(dayjs().subtract(30, 'day'))
      chart.value.zoomX(new Date().getTime() - range, new Date().getTime())
    }

    const zoomto90 = () => {
      let range = dayjs().diff(dayjs().subtract(90, 'day'))
      chart.value.zoomX(new Date().getTime() - range, new Date().getTime())
    }

    const moveForward = () => {
      let range = dayjs().diff(dayjs().subtract(3, 'day'))
      chart.value.zoomX(start.value + range, start.value + range)
    }
    const disableMoveButtons = computed(() => {
      let range = dayjs(end.value).diff(start.value)
      return {
        forward: dayjs(end.value).diff(dayjs(), 'day') > 0,
        back: dayjs(dayjs().subtract(90 / range, 'day')).diff(start.value, 'day') > 90,
      }
    })
    onBeforeMount(async () => {
      await getPatient(props.patient.org.patientId)
      await getChartEvents()
      await getPatientDeviceList()
    })

    const refreshChart = async () => {
      refreshingChart.value = true
      await getPatient(props.patient.org.patientId)
      await getChartEvents()
      await getPatientDeviceList()
      evaluatePatient(props.patient)
      props.patient.eventsArray.forEach(event => {
        if (CONSTS.supportedMetrics[event.kind]) {
          tableHeaders.value.push({
            ...CONSTS.supportedMetrics[event.kind],
          })
        }
        prepareChartPanels()
      })
    }

    const prepareChartPanels = () => {
      let innerPanels = [{ commonName: 'graph' }]
      sortedLatestEvents.value.forEach(event => {
        innerPanels.push(event)
      })

      chartPanels.value = innerPanels
    }

    onMounted(async () => {
      let notification = {
        org: ssoState.user.orgId,
        user: ssoState.user.id,
        patient: props.patient.id,
        timestamp: new Date().getTime(),
      }

      await lastBridgeAccess(notification)
      console.log(props.patient)
      evaluatePatient(props.patient)
      props.patient.eventsArray.forEach(event => {
        console.log('parsing the events', event)
        if (CONSTS.supportedMetrics[event.kind]) {
          tableHeaders.value.push({
            ...CONSTS.supportedMetrics[event.kind],
          })
        }
        prepareChartPanels()
      })

      if (props.chartTab) {
        activeTab.value = props.chartTab
      }
    })

    const toggleStarred = async () => {
      if (props.patient.tags.includes('Practice Starred')) {
        props.patient.tags = props.patient.tags.filter(e => e !== 'Practice Starred')

        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      } else {
        props.patient.tags.push('Practice Starred')

        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      }
    }

    const toggleProviderReview = async () => {
      if (props.patient.tags.includes('Provider Review')) {
        props.patient.tags = props.patient.tags.filter(e => e !== 'Provider Review')

        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      } else {
        props.patient.tags.push('Provider Review')

        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
      }
    }

    return {
      chartPanels,
      selectedPanel,
      allEvents,
      chart,
      chartData,
      chartOptions,
      disableMoveButtons,
      formatDate,
      filteredDays,
      filteredEvents,
      filteredEventsByType,
      filteredTime,
      metricList: CONSTS.metricList,
      moveBack,
      moveForward,
      READING_TYPES,
      ready,
      rpmState,
      showPDF,
      supportedPatientsEvents,
      supportedTypes,
      switchTimeframe,
      tab,
      tableHeaders,
      timeframe,
      toggleSeries,
      typeMapping,
      zoom,
      zoomed,
      props,
      patientDeviceList,
      typeMappingFullName,
      formatTime,
      start,
      end,
      zoomto7,
      zoomto90,
      zoomto30,
      selectedMetric,
      slideTabs,
      slideTabsNext,
      slideTabsPrev,
      firstOptionVisible,
      showPreviousMetric,
      lastOptionVisible,
      showNextMetric,
      focusMetric,
      flowsheet,
      sortedLatestEvents,
      preparingChart,
      updateFocus,
      getPatientDeviceList,
      getChartEvents,
      refreshingChart,
      refreshChart,
      toggleStarred,
      toggleProviderReview,
      activeTab,
    }
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 10.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.novo-sticky-header {
  position: sticky;
  top: 110px;
  z-index: 1;
}

.card-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03),
    0 8px 8px rgba(0, 0, 0, 0.03), 0 16px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.novo-tab {
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.4s;
}
</style>
