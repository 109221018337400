<template>
  <v-sheet color="transparent" flat class="flex py-4">
    <v-fade-transition hide-on-leave>
      <div v-if="patient.rpm.notes.length !== 0 || firstNote">
        <v-row no-gutters class="px-4 flex">
          <v-card class="flex" rounded="lg" flat color="grey lighten-4">
            <v-textarea
              ref="textInput"
              @focus="newNote = true"
              @blur="cleanupNewNote"
              background-color="grey lighten-4"
              hide-details
              solo
              flat
              placeholder="Add a note..."
              auto-grow
              :rows="1"
              class="text-body"
              v-model="newNoteInput"
              @keydown.enter.prevent=""
              @keyup.enter.prevent="submitNote()"
            >
            </v-textarea>

            <v-expand-transition>
              <div style="height: 90px;" v-if="newNote">
                <v-row class="pa-3 pt-10" align="center" no-gutters>
                  <v-spacer />
                  <v-fade-transition>
                    <v-btn color="primary" depressed v-if="newNoteInput && newNoteInput !== ''" @click="submitNote()"
                      >Post</v-btn
                    >
                  </v-fade-transition>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-row>

        <v-row v-if="patient.rpm.notes && patient.rpm.notes.length > 0" class="px-4 py-4" no-gutters>
          <v-col>
            <patient-note-card
              class="mb-4"
              v-for="(note, index) in patient.rpm.notes"
              :key="index"
              :note="note"
              :patient="patient"
              :userDict="orgUserDictionary"
              :needsReview="timestampsOfNotesNeedingReview.includes(note.ts)"
            />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <empty-page
        title="No notes so far"
        detail="Notes from patient interaction and monitoring conducted by the care navigator team will appear here."
        :image="require('@/assets/notes.svg')"
        v-if="patient.rpm.notes.length === 0 && !firstNote"
      >
        <v-btn color="primary" depressed rounded @click="revealNoteStuff">Add a note</v-btn>
      </empty-page>
    </v-fade-transition>
  </v-sheet>
</template>

<script>
import { defineComponent, ref, computed, nextTick, onBeforeMount } from '@vue/composition-api'
import PatientNoteCard from './PatientNoteCard.vue'
import { addPatientNote, getPatient } from '@/shared-ui/store/rpm'
import { state as orgState } from '@/shared-ui/store/org'
import dayjs from 'dayjs'
import EmptyPage from '../EmptyPage.vue'
import { getUserId } from '@/shared-ui/store/sso'
import { updatePatient } from '../../shared-ui/store/patient'
import { addReviewNotification } from '@/shared-ui/store/tasks'
import { getOrgId } from '@/shared-ui/store/sso'
import { state as taskState } from '@/shared-ui/store/tasks'
export default defineComponent({
  components: { PatientNoteCard, EmptyPage },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const newNote = ref(false)
    const newNoteInput = ref(null)
    const newNoteTags = ref([])
    const textInput = ref(null)
    const firstNote = ref(false)
    const userID = ref(null)
    const revealNoteStuff = () => {
      firstNote.value = true
      nextTick(() => {
        textInput.value.focus()
      })
    }

    const createNewNote = () => {
      newNote.value = true
      firstNote.value = true
      nextTick(() => {
        textInput.value.focus()
      })
    }

    const cleanupNewNote = () => {
      if (newNoteInput.value !== null && newNoteInput.value.replace(/[\n\r]/g, '') !== '') {
        console.log('maybe you come back')
      } else {
        newNote.value = false
        firstNote.value = false
        newNoteInput.value = null
      }
    }

    const timestampsOfNotesNeedingReview = computed(() => {
      let tsToDismiss = []
      taskState.providerReviewNotifications.forEach(notification => {
        let notificationID = notification.patient
        let patientID = props.patient.id
        if (notificationID === patientID) {
          tsToDismiss.push(notification.ts)
        }
      })

      // if (props.patient.rpm.notes.length > 0 && props.patient.tags.includes('Provider Review')) {
      //   let latestNote = props.patient.rpm.notes[0]
      //   if (!tsToDismiss.includes(latestNote.ts)) {
      //     tsToDismiss.push(latestNote.ts)
      //   }
      // }
      return tsToDismiss
    })

    const submitNote = async () => {
      if (newNoteInput.value !== null && newNoteInput.value.replace(/[\n\r]/g, '') !== '') {
        let noteToSubmit = newNoteInput.value.replace(/[\n\r]/g, '')
        newNoteInput.value = null
        newNoteTags.value = ['Care Navigator Review']
        props.patient.rpm.notes.unshift({
          userId: userID.value,
          ts: dayjs().toISOString(),
          text: noteToSubmit,
          tags: newNoteTags.value,
          comments: [],
        })

        await addPatientNote(props.patient.id, noteToSubmit, newNoteTags.value)
        // if the patient is in provider review, remove provider review.
        await updatePatient({ id: props.patient.id, tags: { value: props.patient.tags } })
        let newPT = await getPatient(props.patient.org.patientId)
        // get the latest note and set a notification on it.
        let latest = newPT.rpm.notes.sort((a, b) => b.ts - a.ts)[0]
        let orgID = await getOrgId()
        let notification = {
          org: orgID,
          noteAuthor: latest.userId,
          patient: props.patient.id,
          ts: latest.ts,
          notificationTimestamp: new Date().getTime(),
        }
        await addReviewNotification(notification)
        props.patient.rpm.notes = newPT.rpm.notes
        textInput.value.blur()
        newNote.value = false
      } else {
        textInput.value.blur()
        newNote.value = false
        newNoteInput.value = null
      }
    }

    const orgUserDictionary = computed(() => {
      const dict = {}
      orgState.orgUsers.forEach(user => {
        dict[user.id] = { name: 'Unknown', initials: 'UK' }
        if (user.firstName && user.lastName) {
          dict[user.id].name = user.firstName + ' ' + user.lastName
          dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
        }
      })
      return dict
    })

    const scrollToNewest = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    onBeforeMount(async () => {
      setTimeout(() => {
        scrollToNewest()
      }, 100)
      userID.value = await getUserId()
    })
    return {
      newNote,
      newNoteInput,
      submitNote,
      textInput,
      orgState,
      orgUserDictionary,
      firstNote,
      revealNoteStuff,
      cleanupNewNote,
      createNewNote,
      timestampsOfNotesNeedingReview,
    }
  },
})
</script>
