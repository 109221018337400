import CONSTS from './consts'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { timeAgo } from './shared-ui/helpers/data.js'
import { toFahrenheit } from './shared-ui/helpers/data.js'
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(duration)
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export const systemFilterOptions = [
  'Active today',
  'Not active today',
  'Active within last 3 days',
  'Not active within last 3 days',
  'Active within last 30 days',
  'Not active within last 30 days',
]

export const metricFilterOptions = []

export const daysSinceLastReadingOptions = [
  'Active Today',
  'Not Active Today',
  '24h+',
  '3+ Days',
  '7+ Days',
  '30+ Days',
  'No Readings',
]

export const qhpTimeOptions = ['No QHP Time', 'Less than 5', '5 to 10', '10 to 20', 'Close to 20', '20+']

export const relevantMetricsList = []

export const normalizeValue = reading => {
  let kind = reading.kind
  let readingData = reading.data
  let readingObject = {}
  switch (kind) {
    case 'BloodGlucose':
      readingObject.data = parseInt(readingData.value)
      readingObject.tag = 'Glucose'
      break
    case 'BloodOxygen':
      readingObject.data = parseInt(readingData.value)

      readingObject.tag = 'Blood Oxygen'
      break
    case 'BloodPressure':
      readingObject.data = `${readingData.systolicValue}/${readingData.diastolicValue}`

      readingObject.tag = 'Blood Pressure'
      break
    case 'BodyTemp':
      readingObject.data = toFahrenheit(readingData.value, true)
      readingObject.tag = 'Temperature'
      break
    case 'Pulse':
      readingObject.data = parseInt(readingData.value)

      readingObject.tag = 'Heart Rate'
      break
    case 'BodyWeight':
      readingObject.data = (parseFloat(readingData.value) * CONSTS.kgToLbs).toFixed(2)

      readingObject.tag = 'Weight'
      break
    case 'Pain':
      readingObject.data = parseFloat(readingData.value).toFixed(1)
      readingObject.tag = 'Pain'
      break
    case 'Ecg':
      if (readingData.afib) {
        readingObject.data = 'Possible Afib'
      } else {
        readingObject.data = 'No Afib Detected'
      }
      readingObject.tag = 'ECG'
      break
    default:
      return 0
  }
  return readingObject.data
}

export const processReading = reading => {
  let kind = ''

  // translate kind to metric
  let readingObject = null
  if (CONSTS.supportedMetrics[reading.kind] && dayjs().diff(reading.timestamp, 'days') < 182) {
    readingObject = { ...CONSTS.supportedMetrics[reading.kind] }
  } else {
    return null
  }

  let readingData = reading.data

  kind = reading.kind
  switch (kind) {
    case 'BloodGlucose':
      readingObject.data = parseInt(readingData.value)
      readingObject.tag = 'Glucose'
      break
    case 'BloodOxygen':
      readingObject.data = parseInt(readingData.value)

      readingObject.tag = 'Blood Oxygen'
      break
    case 'BloodPressure':
      readingObject.data = `${readingData.systolicValue}/${readingData.diastolicValue}`

      readingObject.tag = 'Blood Pressure'
      break
    case 'BodyTemp':
      readingObject.data = toFahrenheit(readingData.value, true)
      readingObject.tag = 'Temperature'
      break
    case 'Pulse':
      readingObject.data = parseInt(readingData.value)

      readingObject.tag = 'Heart Rate'
      break
    case 'BodyWeight':
      readingObject.data = (parseFloat(readingData.value) * CONSTS.kgToLbs).toFixed(2)

      readingObject.tag = 'Weight'
      break
    case 'Pain':
      readingObject.data = parseFloat(readingData.value).toFixed(1)
      readingObject.tag = 'Pain'
      break
    case 'Ecg':
      if (readingData.afib) {
        readingObject.data = 'Possible Afib'
      } else {
        readingObject.data = 'No Afib Detected'
      }
      readingObject.tag = 'ECG'
      break
    default:
      return {
        title: 'Unknown',
        time: timeAgo(dayjs().diff(reading.timestamp)),
        icon: 'mdi-help',
        data: 0,
        units: '-',
        tag: 'Unknown',
        timestamp: new Date(reading.timestamp).getTime(),
        humanDate: new Date(reading.timestamp),
        kind: kind,
      }
  }

  readingObject.time = specificRelativeTimeWithHoursAndMinutes(dayjs(reading.timestamp))

  if (!readingObject.data) {
    readingObject.data = '-'
  }

  readingObject.timestamp = new Date(reading.timestamp).getTime()
  readingObject.humanDate = new Date(reading.timestamp)
  readingObject.alert = reading.alert
  return readingObject
}

export const evaluatePatient = patient => {
  // Step Zero: process the most recent readings so you dont have to do it in the card? to make it faster to display?

  patient.eventsArray = []
  patient.systemTags = []
  patient.metricTags = []
  if (patient.latestEvents) {
    let keys = Object.keys(patient.latestEvents)
    keys.forEach(key => {
      let processed = processReading(patient.latestEvents[key])
      if (processed) {
        if (!patient.systemTags.includes(processed.tag)) {
          patient.systemTags.push(processed.tag)
        }
        if (!metricFilterOptions.includes(processed.tag)) {
          metricFilterOptions.push(processed.tag)
        }

        if (!relevantMetricsList.find(element => element.text === processed.tag)) {
          relevantMetricsList.push({ icon: processed.icon, text: processed.tag, kind: processed.kind })
        }

        if (patient.rpm?.newAlertsArray.includes(key)) {
          processed.unreadAlert = true
        }
        patient.eventsArray.push(processed)
      }
    })
    patient.eventsArray.sort(function(a, b) {
      if (a.timestamp > b.timestamp) return -1
      if (a.timestamp < b.timestamp) return 1
      return 0
    })

    patient.eventsArray.sort(function(a, b) {
      if (a.unreadAlert > b.unreadAlert) return -1
      if (a.unreadAlert < b.unreadAlert) return 1
      return 0
    })
  }

  // Step One: Rank the patient by activity and apply the system filters (for categorization)

  // activity indicator
  // 0 = things are good (patient took a reading today)
  //1 = things are OK (patient took a reading in the last 48 hours)
  // 2 = things are in danger (patient took a reading within the last 7 days)
  //3 = things are not ok. patient hasnt taken a reading in the last 7 days
  // 4. things are not ok... no readings...

  if (patient.rpm && patient.rpm.lastData && patient.rpm.lastData && dayjs(patient.rpm.lastData).isToday()) {
    patient.systemTags.push('Active Today')
    patient.complianceScore = 0
  } else {
    patient.systemTags.push('Not Active Today')
    // how inactive are they? more than two days but less than 7? flag them as high priority?
    if (patient.rpm && patient.rpm.lastData) {
      const date1 = dayjs(patient.rpm.lastData)
      const date2 = dayjs(new Date())
      if (dayjs(patient.rpm.lastData).isYesterday()) {
        patient.systemTags.push('Active Yesterday')
        patient.complianceScore = 1
      } else {
        patient.systemTags.push('Not Active Yesterday')
      }
      if (date2.diff(date1) >= 86400000) {
        patient.systemTags.push('24+')
        patient.complianceScore = 2
      }
      if (date2.diff(date1) >= 86400000 * 3) {
        patient.systemTags.push('3+ Days')
        patient.complianceScore = 2
      }

      if (date2.diff(date1) >= 86400000 * 7) {
        patient.systemTags.push('7+ Days')
        patient.complianceScore = 2
      }
      if (date2.diff(date1) >= 86400000 * 30) {
        patient.systemTags.push('30+ Days')
        patient.complianceScore = 2
      }
    } else {
      patient.systemTags.push('No Readings')
      patient.complianceScore = 4
    }
  }

  //Step Two: determine how much staff time has been spent with the patient this month.
  // this is where we set the qhp time for sorting and create the human readable qhp string for display so we dont have to calculate it in the card?
  // TODO: human readable qhp display

  if (patient.rpm && patient.rpm.qhpTime && patient.rpm.qhpTime.ms) {
    let monthForTime = parseInt(patient.rpm.qhpTime.periodStart.substring(5, 7))

    let thisMonth = new Date()

    if (monthForTime === thisMonth.getMonth() + 1) {
      patient.qhpForSorting = patient.rpm.qhpTime.ms
      var minutes = Math.floor(patient.rpm.qhpTime.ms / 60000)
      var seconds = ((patient.rpm.qhpTime.ms % 60000) / 1000).toFixed(0)
      patient.qhpTimeDisplay =
        seconds == 60 ? minutes + 1 + ':00' : minutes + 'm ' + (seconds < 10 ? '0' : '') + seconds + 's'

      // 0-5 5-10 10-15 15-20 20+
      if (patient.qhpForSorting <= 60000 * 5) {
        patient.systemTags.push('Less than 5')
      } else if (patient.qhpForSorting <= 60000 * 10) {
        patient.systemTags.push('5 to 10')
      } else if (patient.qhpForSorting <= 60000 * 20) {
        patient.systemTags.push('10 to 20')
      } else {
        patient.systemTags.push('20+')
      }
    } else {
      patient.qhpForSorting = 0
      patient.qhpTimeDisplay = '0m 0s'
      patient.systemTags.push('No QHP Time')
    }

    if (
      monthForTime === thisMonth.getMonth() + 1 &&
      patient.rpm.qhpTime.ms > 900000 &&
      patient.rpm.qhpTime.ms < 1200000
    ) {
      patient.systemTags.push('Close to 20')
    }
  } else {
    patient.qhpForSorting = 0
    patient.systemTags.push('No QHP Time')
  }

  // Step Three: set the length of time since the last reading to easily sort the patients

  if (patient.rpm && patient.rpm.lastData && patient.rpm.lastData) {
    patient.readingForSorting = new Date(patient.rpm.lastData).getTime()
  } else {
    patient.readingForSorting = 0
  }

  // Step Four: set a human readable reccomended action based on the most recent reading.
  // this should get more sophisticated once we have the data around when their time period ends and how many readings they have had this month.
  // for now its a simple how many days has it been since their last reading (basically which compliance score they have determines what the nurse should do)

  // determine the next probable action needed for this patient based on how compliant they are, how much time has been spent with the patient, alerts, etc
  let lastReadingDate = 'a long time ago.'
  if (patient.rpm && patient.rpm.lastData) {
    lastReadingDate = timeAgo(dayjs().diff(patient.rpm.lastData))
  }

  if (patient.complianceScore === 0) {
    patient.activitySummaryStatement = patient.firstName + "'s last reading was " + lastReadingDate + '.'
  } else if (patient.complianceScore === 1) {
    patient.activitySummaryStatement =
      patient.firstName + "'s last reading was yesterday but they haven't yet taken a reading today."
  } else if (patient.complianceScore === 2) {
    patient.activitySummaryStatement =
      patient.firstName +
      "'s last reading was " +
      lastReadingDate +
      '. They need to take a reading today to get back on track.'
  } else if (patient.complianceScore === 3) {
    patient.activitySummaryStatement =
      patient.firstName + "'s last reading was " + lastReadingDate + '. They are at high risk of not qualifying.'
  } else if (patient.complianceScore === 4) {
    patient.activitySummaryStatement = patient.firstName + ' has not yet taken a reading.'
  } else {
    patient.activitySummaryStatement = ' no score' + patient.complianceScore
  }
}

export const lookupProblem = async terms => {
  console.log(terms)
  function parseUrl(url, hash) {
    if (url.indexOf('?') !== -1) {
      let queryParams = []
      const baseUrl = url.split('?').shift()
      const params = url
        .split('?')
        .pop()
        .split('&')
      for (let i = 0; i < params.length; i++) {
        const param = params[i]
        if (hash[param] !== undefined) {
          queryParams.push(param + '=' + encodeURIComponent(hash[param]))
        }
      }
      return baseUrl + '?' + queryParams.join('&')
    }
    return url
  }

  let url = parseUrl('https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?terms&maxList', { terms, maxList: 10 })
  url = url + '&sf=code,name'

  const response = await fetch(url)
  const data = await response.json()
  let items = []
  for (let i = 0; i < data[1].length; i++) {
    let itemToReturn = {}
    let code = data[1][i]
    itemToReturn.name = data[3][i][1]
    itemToReturn.code = code
    itemToReturn.description = code + ' ' + itemToReturn.name
    itemToReturn.compound = code + '|' + itemToReturn.name
    items.push(itemToReturn)
  }
  return items
}
