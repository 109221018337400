<template>
  <bottom-sheet-panel
    tooltip="Settings"
    :activatorIcon="'mdi-cog-outline'"
    title="Settings"
    :maxHeight="75"
    :fullscreen="true"
    ref="sheet"
  >
    <threshold-alert-rules-panel @submitNew="addThreshold" v-if="orgRules" :inboundRulesObject="orgRules" />
  </bottom-sheet-panel>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import BottomSheetPanel from './BottomSheetPanel.vue'
import { getOrgAlertRules, setOrgAlertRules } from '../shared-ui/store/rpm'
import ThresholdAlertRulesPanel from './ThresholdAlertRulesPanel.vue'

export default defineComponent({
  components: { BottomSheetPanel, ThresholdAlertRulesPanel },
  props: {
    patientList: {
      type: Array,
      default: () => {
        return []
      },
    },
    fetchingPatients: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const orgRules = ref({})

    const addThreshold = () => {
      let alertRules = orgRules.value
      setOrgAlertRules(alertRules)
    }

    onMounted(async () => {
      orgRules.value = await getOrgAlertRules()
    })

    return {
      props,
      orgRules,
      addThreshold,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
