<template>
  <v-btn
    @click="toggleTimer"
    depressed
    :style="session ? 'font-weight:bold; color: white; background-color: #4CAF50' : 'font-weight:bold;'"
    class="pl-3 ml-2"
    rounded
  >
    <v-icon left v-if="session">mdi-stop</v-icon>
    <v-icon left v-else>mdi-clock-outline</v-icon>
    {{ sessionLengthString }}
  </v-btn>
</template>

<script>
import { defineComponent, onMounted, ref, onBeforeUnmount } from '@vue/composition-api'
import { startRpmSession, updateRpmSession } from '../../shared-ui/store/rpm'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const sessionStart = ref(null)
    const session = ref(null)
    const sessionLengthString = ref('00:00')
    const timerColor = ref('green')
    const sessionID = ref(null)
    const heartbeatTimer = ref(null)

    onMounted(async () => {
      await initiateSession()
    })

    const initiateSession = async () => {
      session.value = null
      sessionStart.value = new Date().getTime()
      sessionID.value = await startRpmSession(props.patient.id)
      session.value = setInterval(function() {
        const sessionInSeconds = Math.floor((new Date().getTime() - sessionStart.value) / 1000)

        const minutes = Math.floor(sessionInSeconds / 60)
        const seconds = sessionInSeconds % 60
        let string
        let minutesToAdd = '0' + minutes
        minutesToAdd = minutesToAdd.slice(-2)
        string = minutesToAdd + ':'
        let secondsToAdd = '0' + seconds

        secondsToAdd = secondsToAdd.slice(-2)
        string = string + secondsToAdd

        sessionLengthString.value = string
      }, 1000)

      heartbeatTimer.value = setInterval(async function() {
        await updateRpmSession(sessionID.value)
      }, 30000)
      timerColor.value = 'green'
    }

    const toggleTimer = async () => {
      if (session.value) {
        sessionLengthString.value = '00:00'
        timerColor.value = 'grey lighten-2'
        clearInterval(session.value)
        await updateRpmSession(sessionID.value)
        clearInterval(heartbeatTimer.value)
        session.value = null
        sessionID.value = null
      } else {
        await initiateSession()
      }
    }

    onBeforeUnmount(async () => {
      clearInterval(session.value)
      await updateRpmSession(sessionID.value)
      clearInterval(heartbeatTimer.value)
    })
    return {
      sessionLengthString,
      timerColor,
      toggleTimer,
      session,
    }
  },
})
</script>
