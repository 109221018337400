<template>
  <svg :height="size" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.61324 21.2544V4.13764H0V23.8676H25V21.2544H2.61324Z" :fill="color" fill-opacity="0.8" />
    <path d="M8.31882 12.3258H5.70558V18.7282H8.31882V12.3258Z" :fill="color" fill-opacity="0.8" />
    <path d="M12.587 7.83971H9.97379V18.7282H12.587V7.83971Z" :fill="color" fill-opacity="0.8" />
    <path d="M17.2038 0H14.5906V18.7282H17.2038V0Z" :fill="color" fill-opacity="0.8" />
    <path d="M22.169 10.5836H19.5558V18.7282H22.169V10.5836Z" :fill="color" fill-opacity="0.8" />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    size: {
      type: String,
      default: '18',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  setup() {},
})
</script>
