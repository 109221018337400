<template>
  <div>
    <v-card color="taskboardCard" flat rounded="lg" @click="$emit('patient-click')">
      <v-row class="px-4 pl-3 py-1" no-gutters>
        <div>
          <v-row no-gutters justify="center">
            <v-btn class="mr-2" @click.stop.prevent="$emit('toggleStarred', patient)" icon>
              <v-icon color="#F7CB4E" v-if="patient.tags.includes('Practice Starred')">mdi-star</v-icon>
              <v-icon color="grey lighten-2" v-else>mdi-star-outline</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="false" no-gutters justify="center">
            <v-icon class="mr-2" small color="red" v-if="patient.tags.includes('Provider Review')"
              >mdi-flag-variant</v-icon
            >
          </v-row>
        </div>
        <v-col>
          <v-row class="pt-2 pb-1" align="center" no-gutters>
            <span class="text-body-2 font-weight-regular"
              >{{ patient.firstName }} <span class="text-body-2 font-weight-bold">{{ patient.lastName }}</span></span
            >
            <v-spacer></v-spacer>

            <div style="font-size: 11px; position: relative; width: 60px; color: white; font-weight: bold; ">
              <v-progress-linear style="width: 60px" height="16" rounded :value="qhpPercentage" />
              <div
                v-if="patient && patient.rpm && patient.rpm.qhpTime"
                style="position: absolute; top: 0px; left: 0px; text-align: center; width: 60px"
              >
                {{ msToMinutesAndSeconds(patient.rpm.qhpTime.ms) }}
              </div>
            </div>

            <!-- <div
              v-if="patient.tags.includes('Provider Review')"
              style="font-size: 11px; position: relative; width: 60px; height:17px; color: white; font-weight: bold; background-color: #F44336; border-radius:4px; overflow:hidden"
            >
              <div
                style="position: absolute; top: 0px; left: 0px; text-align: center; width: 60px; background-color: #F44336"
              >
                Review
              </div>
            </div> -->

            <!-- <v-progress-linear :value="qhpPercentage" height="10" style="max-width: 60px;" rounded /> -->
          </v-row>
          <div v-if="!showLatestNote">
            <div v-if="eventsArray.length > 0">
              <v-card
                class="pb-2 flex"
                v-for="(reading, idx) in eventsArray"
                :key="idx + 'reading'"
                color="transparent"
                flat
                v-show="idx < 1"
              >
                <v-row class="flex" align="center" no-gutters>
                  <v-icon class="mr-1" :color="reading.alert ? 'yellow' : 'red lighten-1'" :size="14">{{
                    reading.icon
                  }}</v-icon>
                  <div v-if="reading.data" class="reading-display-data text-body-2 font-weight-medium">
                    {{ reading.data }} <span class="font-weight-regular text-body-2">{{ reading.units }} </span>
                  </div>
                  <v-spacer></v-spacer>
                  <span class="text-body-2">{{ reading.time }}</span>
                </v-row>
              </v-card>
            </div>
            <v-card flat color="transparent" height="25" class="flex" v-else>
              <v-row class="flex" align="center" no-gutters>
                <div class="reading-display-data text-body-2 font-weight-medium red--text">
                  No recent readings
                </div>
              </v-row>
            </v-card>
          </div>
          <div class="pb-2" v-if="showLatestNote">
            <v-row class="text--secondary" justify="space-between" no-gutters>
              <span class="text-body-2">{{ latestProviderReviewNote.author }}</span>
              <span class="text-body-2">{{ latestProviderReviewNote.date }}</span>
            </v-row>

            <v-row class="text--secondary" no-gutters>
              <span>
                <span
                  style="font-style: italic"
                  v-if="latestProviderReviewNote.text.length < 119"
                  class="text-body-2"
                  >{{ latestProviderReviewNote.text }}</span
                >
                <span class="text-body-2" style="font-style: italic" v-else
                  >{{ latestProviderReviewNote.text.slice(0, 120) }}...</span
                >
              </span>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="!lastOne" style="margin-left: 54px;" />
    </v-card>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { timeAgo } from '@/shared-ui/helpers/data.js'
import AnalyticsIcon from '@/shared-ui/icons/AnalyticsIcon.vue'
import TextMessagingIcon from '@/shared-ui/icons/TextMessagingIcon'
import PatientAlertNotificationReadingDisplay from '@/components/PatientAlertNotificationReadingDisplay.vue'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import { relevantMetricsList, processReading } from '@/nurse-sidekick'
import { msToMinutesAndSeconds } from '@/helpers/time'

export default defineComponent({
  components: { AnalyticsIcon, PatientAlertNotificationReadingDisplay, TextMessagingIcon },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    lastOne: {
      type: Boolean,
      default: false,
    },
    alertType: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    showLatestNote: {
      type: Boolean,
      default: false,
    },
    latestProviderReviewNote: {
      type: Object,
      default: null,
    },
    importantMetrics: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup(props) {
    const showAll = ref(false)
    const patientFullName = ref('')
    const patientAge = ref('')
    const patientGender = ref('')
    const orgPatientId = ref('')
    const time = ref(null)
    const latestAlertsArray = ref([])
    const qhpTime = ref(null)
    const qhpPercentage = ref(0)
    const latestReadings = ref([])
    const dateOfLastReading = ref('')

    const patientFirstName = ref('')

    const eventsArray = computed(() => {
      const events = []
      if (props.patient.latestEvents) {
        let keys = Object.keys(props.patient.latestEvents)
        keys.forEach(key => {
          let processed = processReading(props.patient.latestEvents[key])
          if (processed) {
            if (props.patient.rpm?.newAlertsArray.includes(key)) {
              processed.unreadAlert = true
            }
            events.push(processed)
          }
        })
      }
      if (props.alertType) {
        return events.sort(function(a, b) {
          if (a.unreadAlert > b.unreadAlert) return -1
          if (a.unreadAlert < b.unreadAlert) return 1
          return 0
        })
      }
      return events.sort(function(a, b) {
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp < b.timestamp) return 1
        return 0
      })
    })

    const currentMonth = dayjs(new Date().getTime()).format('MMM')

    const patientActivityComplianceStatement = ref(props.patient.activitySummaryStatement)

    const goToChart = () => {
      if (orgPatientId) {
        const url = new URL(location.href)
        url.searchParams.set('patient', orgPatientId.value)
        location.href = url.href
      }
    }

    const metricsToDisplay = computed(() => {
      let toDisplay = []
      relevantMetricsList.forEach(metric => {
        if (props.importantMetrics.includes(metric.text)) {
          toDisplay.push(metric.kind)
        }
      })

      return toDisplay
    })

    onMounted(() => {
      patientFirstName.value = props.patient.firstName
      patientFullName.value = props.patient.firstName + ' ' + props.patient.lastName
      if (props.patient.birthdate) {
        patientAge.value = dayjs().diff(props.patient.birthdate, 'years')
      }
      orgPatientId.value = props.patient.org?.patientId
      patientGender.value = props.patient.gender?.charAt(0)
      time.value = timeAgo(dayjs().diff(props.patient.rpm?.lastAlert, 'milliseconds'))

      // find the latest alert event in latestEvents by the key in the newAlertsArray
      // props.patient.rpm?.newAlertsArray.forEach(alert => {
      //   latestAlertsArray.value.push(props.patient.latestEvents[alert])
      // })

      if (props.patient.latestEvents) {
        let keys = Object.keys(props.patient.latestEvents)
        keys.forEach(key => {
          if (props.patient.rpm?.newAlertsArray.includes(key)) {
            latestAlertsArray.value.push(props.patient.latestEvents[key])
          } else {
            latestReadings.value.push(props.patient.latestEvents[key])
          }
        })
        latestReadings.value.sort(function(a, b) {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        })
      }

      if (props.patient.rpm && props.patient.rpm.qhpTime && props.patient.rpm.qhpTime.ms) {
        qhpPercentage.value = (props.patient.rpm.qhpTime.ms / 1200000) * 100
        let monthForTime = parseInt(props.patient.rpm.qhpTime.periodStart.substring(5, 7))

        let thisMonth = new Date()

        if (monthForTime === thisMonth.getMonth() + 1) {
          var minutes = Math.floor(props.patient.rpm.qhpTime.ms / 60000)
          var seconds = ((props.patient.rpm.qhpTime.ms % 60000) / 1000).toFixed(0)
          qhpTime.value =
            seconds == 60 ? minutes + 1 + ':00' : minutes + 'm ' + (seconds < 10 ? '0' : '') + seconds + 's'
        } else {
          qhpTime.value = '0m 0s'
        }
      }

      // if still not alerts then use the lastAlert timestamp
      if (!latestAlertsArray.value.length) {
        Object.keys(props.patient.latestEvents).forEach(key => {
          if (props.patient.latestEvents[key].timestamp === props.patient.rpm.lastAlert) {
            latestAlertsArray.value.push(props.patient.latestEvents[key])
          }
        })
      }

      if (props.patient.rpm.lastAlert) {
        dateOfLastReading.value = timeAgo(dayjs().diff(props.patient.rpm.lastAlert))
      }
    })
    return {
      props,
      goToChart,
      latestAlertsArray,
      latestReadings,
      orgPatientId,
      patientAge,
      patientFullName,
      patientGender,
      showAll,
      time,
      qhpTime,
      currentMonth,
      patientFirstName,
      dateOfLastReading,
      patientActivityComplianceStatement,
      metricsToDisplay,
      qhpPercentage,
      eventsArray,
      msToMinutesAndSeconds,
    }
  },
})
</script>
<style scoped>
.card-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.005), 0 2px 2px rgba(0, 0, 0, 0.005), 0 4px 4px rgba(0, 0, 0, 0.005),
    0 8px 8px rgba(0, 0, 0, 0.005), 0 16px 16px rgba(0, 0, 0, 0.005);
  border-radius: 8px;
}
</style>
