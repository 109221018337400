<template>
  <v-bottom-sheet scrollable v-model="sheet">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" @click="$emit('active')" class="flex" color="primary" depressed
        >Add a Device</v-btn
      >
    </template>
    <v-card tile height="80vh">
      <v-toolbar height="64" flat>
        <span class="text-h6 font-weight-bold text--secondary">
          <portal-target name="enrollmentModalTitle"> </portal-target>
        </span>
        <v-spacer />
        <v-btn icon><v-icon @click="sheet = false">mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <enrollment-flow
          @enrollmentFlowCompleteWithErrors="handleError()"
          @enrollmentFlowComplete="handleSuccess(), $emit('refreshDeviceList')"
          :currentPatient="true"
          v-if="sheet"
          :protoPatient="patient"
          :fullscreen="false"
        />
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import EnrollmentFlow from './EnrollmentFlow.vue'
import { state as rpmState } from '@/shared-ui/store/rpm'

export default defineComponent({
  components: { EnrollmentFlow },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    devices: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup() {
    onMounted(() => {})
    const sheet = ref(false)
    const currentPatient = ref(rpmState.patient)
    const showEnrollment = ref(false)

    const handleError = () => {
      console.log('error')
    }
    const handleSuccess = () => {
      setTimeout(function() {
        sheet.value = false
      }, 1500)
    }
    return {
      close,
      showEnrollment,
      currentPatient,
      handleError,
      handleSuccess,
      sheet,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
