<template>
  <v-card flat tile>
    <portal v-if="fullscreen" to="pageTitle">
      <span class="primary--text" v-if="enrollmentProgress === 0">Get started</span>
      <span v-if="enrollmentProgress === 1">{{ selectedDevice.class }}</span>
      <span v-if="enrollmentProgress === 2"></span>
    </portal>

    <portal v-if="!fullscreen" to="enrollmentModalTitle">
      <span class="primary--text" v-if="enrollmentProgress === 0">Add a connected device</span>
      <span v-if="enrollmentProgress === 1">{{ selectedDevice.class }}</span>
      <span v-if="enrollmentProgress === 2"></span>
    </portal>

    <portal v-if="fullscreen" to="logoSpace">
      <div>
        <v-slide-x-reverse-transition hide-on-leave leave-absolute>
          <v-img v-if="enrollmentProgress === 0" width="100" :src="require('@/assets/ArrowSmallLogo.png')" />
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave leave-absolute>
          <v-hover v-if="enrollmentProgress === 1" v-slot="{ hover }">
            <div @click="enrollmentProgress = 0" style="cursor: pointer;">
              <v-row style="margin-left: -5px;" align="center" no-gutters>
                <v-icon :color="hover ? 'primary' : ''">mdi-chevron-left</v-icon>
                <span style="font-weight: 600; font-size: 14px;" :class="hover ? 'primary--text' : ''">DEVICES</span>
              </v-row>
            </div>
          </v-hover>
        </v-slide-x-reverse-transition>
      </div>
    </portal>

    <v-tabs-items v-model="enrollmentProgress">
      <v-tab-item>
        <v-row no-gutters class="px-4 pb-2">
          <span style="font-weight: 900; font-size: 20px; opacity: 0.7">Select a device to begin</span>
        </v-row>
        <v-row style="font-weight:300; font-size: 16px;" no-gutters class="px-4 pb-4 text--secondary">
          Choose the appropriate device from the list below. Devices arrive pre-configured and ready to use. You'll be
          able to customize delivery and other details on the next page.
        </v-row>
        <v-container class="px-2" fluid>
          <v-row no-gutters>
            <v-col class="pa-2" v-for="product in products" :key="product.value" cols="6" style="position: relative">
              <v-card
                :disabled="!product.availableForOrder"
                @click="handleDeviceClick(product)"
                color="white"
                style="position: relative"
                flat
              >
                <v-card
                  flat
                  rounded="lg"
                  color="#f2f2f2"
                  style="background: radial-gradient(#ffffff, #f3f4f7);"
                  class="flex"
                >
                  <v-row no-gutters align="center" justify="center" class="py-7">
                    <v-img width="70" height="70" contain :src="product.image" />
                  </v-row>
                </v-card>

                <v-card class="py-1 pb-4" flat>
                  <v-row no-gutters style="font-weight: bold; font-size: 13px; opacity: 0.8"
                    >{{ product.class }}
                  </v-row>
                  <v-row style="font-size: 13px; opacity: 0.8" no-gutters>
                    {{ product.title }}
                  </v-row>
                  <v-row style="font-size: 13px; opacity: 0.6" no-gutters>
                    {{ product.subtitle }}
                  </v-row>
                </v-card>
              </v-card>
              <v-chip
                dark
                v-if="!product.availableForOrder"
                style="top: 17px;; right: 17px; position: absolute"
                x-small
                color="grey lighten-1"
                >Available Soon</v-chip
              >
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row no-gutters class="px-4 py-8 pb-0 pt-0" justify="center">
          <v-card
            color="grey lighten-5"
            style="background: radial-gradient(#ffffff, #f3f4f7);"
            v-if="selectedDevice"
            class="px-2 flex pt-7 pb-4"
            flat
          >
            <v-row no-gutters justify="center">
              <v-list-item-avatar class="mr-4" tile width="120" height="120">
                <v-img contain :src="selectedDevice.image" />
              </v-list-item-avatar>
            </v-row>
            <v-list-item style="text-align: center">
              <v-list-item-content>
                <v-list-item-title>{{ selectedDevice.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ selectedDevice.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row no-gutters class="px-4">
          <v-form ref="patientForm" class="form" v-model="isPatientFormValid">
            <!-- patient name -->
            <v-subheader class="pa-0 ma-0">Patient Information</v-subheader>
            <!-- <v-chip style="font-weight:bold;" class="my-5" small color="primary lighten-1"
                >Patient Information</v-chip
              > -->
            <v-row no-gutters>
              <v-col class="py-0" cols="12">
                <v-text-field
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="formInput.firstName"
                  :rules="rules.required"
                  placeholder="First Name"
                  required
                >
                  <v-icon small v-if="formInput.firstName && formInput.firstName !== ''" color="primary" slot="append"
                    >mdi-check-circle</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12">
                <v-text-field
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="formInput.lastName"
                  :rules="rules.required"
                  placeholder="Last Name"
                  required
                >
                  <v-icon small v-if="formInput.lastName && formInput.lastName !== ''" color="primary" slot="append"
                    >mdi-check-circle</v-icon
                  >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="6">
                <v-select
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="formInput.gender"
                  :rules="rules.required"
                  :items="genderOptions"
                  placeholder="Sex"
                  required
                  class="mr-3 mt-1"
                >
                  <v-icon small v-if="formInput.gender && formInput.gender !== ''" color="primary" slot="append"
                    >mdi-check-circle</v-icon
                  >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-text-field
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-mask="mask.birthdate"
                  :rules="rules.required"
                  v-model="formInput.birthdate"
                  placeholder="Date of Birth"
                  required
                  hint="MM/DD/YYYY"
                  persistent-hint
                  class="mt-1"
                >
                  <v-icon small v-if="formInput.birthdate && formInput.birthdate !== ''" color="primary" slot="append"
                    >mdi-check-circle</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="formInput.smsPhone"
                  placeholder="Patient Phone"
                  v-mask="mask.phone"
                  validate-on-blur
                  :rules="[
                    v => !!v || 'Phone number is required',
                    v => v.length > 11 || 'Valid phone number is required',
                    phoneValidated === true || 'Phone number in use by another patient',
                  ]"
                  hint="SMS capable phone is preferred"
                  @keyup="checkSMSPhone(formInput.smsPhone)"
                >
                  <v-icon
                    small
                    v-if="
                      formInput.smsPhone &&
                        formInput.smsPhone !== '' &&
                        formInput.smsPhone.length > 11 &&
                        phoneValidated
                    "
                    color="primary"
                    slot="append"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon small v-if="!phoneValidated" color="red" slot="append">mdi-alert-circle</v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-card class="flex" flat>
                <!-- <v-subheader class="px-0">Reason for monitoring</v-subheader> -->
                <!-- <v-chip style="font-weight:bold;" class="my-5" small color="primary  lighten-1"
                    >Reason for monitoring</v-chip
                  > -->

                <v-autocomplete
                  full-width
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="selectedConditionObject"
                  :items="items"
                  :loading="isLoadingConditions"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="name"
                  placeholder="Search by code or keyword"
                  return-object
                  no-filter
                  :multiple="false"
                  append-icon=""
                  @change="selectedCondition(selectedConditionObject)"
                  required
                  :rules="rules.required"
                  label="Reason for monitoring"
                  hint="Searching the NIH ICD-10 Database"
                >
                  <template v-slot:item="data">
                    <span class="text-truncate" style="max-width: 240px">
                      <span class="font-weight-bold">{{ data.item.code }}</span>
                      <span class="ml-3">
                        {{ data.item.name }}
                      </span>
                    </span>
                  </template>

                  <template v-slot:prepend-inner>
                    <v-icon v-if="!selectedConditionObject">mdi-magnify</v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon small color="primary" v-if="selectedConditionObject">mdi-check-circle</v-icon>
                  </template>

                  <template v-slot:selection="data">
                    <span class="text-truncate" style="width: 240px">
                      <span class="font-weight-bold">{{ data.item.code }}</span>
                      <span class="ml-3">
                        {{ data.item.name }}
                      </span>
                    </span>
                  </template>
                </v-autocomplete>
              </v-card>
            </v-row>
          </v-form>
        </v-row>
        <v-form v-if="selectedDevice" ref="deviceForm" v-model="selectedDevice.readyToAssign">
          <v-row class="px-4 pt-0" no-gutters>
            <v-card class="flex" flat>
              <v-subheader class="px-0">Device delivery method</v-subheader>
              <!-- <v-chip style="font-weight:bold;" class="my-5 mb-3" small color="primary  lighten-1"
                  >Device delivery method</v-chip
                > -->
              <v-radio-group row class="pt-0" v-model="selectedDevice.selectedEnrollmentOption">
                <v-radio label="Assign in office" :value="enrollOptions[0].value"> </v-radio>
                <v-radio label="Ship to patient" :value="enrollOptions[1].value"> </v-radio>
              </v-radio-group>
            </v-card>
          </v-row>
          <v-row class="px-4 " v-if="selectedDevice" no-gutters>
            <span ref="selectedAssign"></span>
            <v-slide-x-transition hide-on-leave>
              <div class="flex" v-if="selectedDevice.selectedEnrollmentOption === 'assign'">
                <v-subheader class="px-0">Device Identifier</v-subheader>
                <v-text-field
                  dense
                  flat
                  solo
                  background-color="#f3f4f7"
                  v-model="selectedDevice.deviceId"
                  :rules="[
                    v => !!v || 'Device ID is required',
                    v => selectedDevice.idValidation(v) || 'Recheck device ID',
                  ]"
                  :label="selectedDevice.idLabel"
                  required
                >
                  <v-icon
                    small
                    v-if="selectedDevice.deviceId && selectedDevice.idValidation(selectedDevice.deviceId)"
                    color="primary"
                    slot="append"
                    >mdi-check-circle</v-icon
                  >
                </v-text-field>
              </div>
            </v-slide-x-transition>
            <!-- address -->
            <v-slide-x-transition hide-on-leave>
              <div class="flex" v-if="selectedDevice.selectedEnrollmentOption === 'order'">
                <v-subheader class="px-0">Mailing Address</v-subheader>
                <!-- <v-chip class="my-3 mt-4" small color="primary">Mailing Address</v-chip> -->

                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      flat
                      solo
                      background-color="#f3f4f7"
                      v-model="formInput.address.line1"
                      :rules="rules.required"
                      placeholder="Street Address"
                      required
                    >
                      <v-icon
                        small
                        v-if="formInput.address.line1 && formInput.address.line1 !== ''"
                        color="primary"
                        slot="append"
                        >mdi-check-circle</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      flat
                      solo
                      background-color="#f3f4f7"
                      v-model="formInput.address.line2"
                      placeholder="Street Address Line 2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      flat
                      solo
                      background-color="#f3f4f7"
                      :rules="rules.required"
                      v-model="formInput.address.city"
                      placeholder="City"
                      required
                    >
                      <v-icon
                        small
                        v-if="formInput.address.city && formInput.address.city !== ''"
                        color="primary"
                        slot="append"
                        >mdi-check-circle</v-icon
                      ></v-text-field
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      dense
                      flat
                      solo
                      background-color="#f3f4f7"
                      :rules="rules.requiresValidState(stateList)"
                      v-model="formInput.address.state"
                      placeholder="State"
                      :items="stateList"
                      required
                      class="mr-2"
                      ><v-icon
                        small
                        v-if="formInput.address.state && formInput.address.state !== ''"
                        color="primary"
                        slot="append"
                        >mdi-check-circle</v-icon
                      ></v-select
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      flat
                      solo
                      background-color="#f3f4f7"
                      :rules="rules.required"
                      v-model="formInput.address.zip"
                      v-mask="mask.zip"
                      placeholder="Postal Code"
                      required
                      ><v-icon
                        small
                        v-if="formInput.address.zip && formInput.address.zip !== ''"
                        color="primary"
                        slot="append"
                        >mdi-check-circle</v-icon
                      ></v-text-field
                    >
                  </v-col>
                </v-row>
              </div>
            </v-slide-x-transition>
          </v-row>
          <v-divider class="mx-4 mb-6" />
          <v-row class="px-4 pt-0" no-gutters>
            <v-card class="flex" flat>
              <!-- <v-subheader class="px-0">Device delivery</v-subheader> -->

              <v-card outlined color="#f3f4f7" rounded="lg" flat class="pa-3 pt-4">
                <span style="font-weight: bold; font-size: 18px;">Patient Consent</span>
                <v-card-text class="pa-0 pt-2">
                  The practitioner must secure the beneficiary’s consent to receive Remote Monitoring Services either
                  prior to or at the initiation of the service. Such consent must include an acknowledgement that the
                  beneficiary will be responsible for the copayment or deductible associated with the services. The
                  beneficiary may consent verbally, but it must be documented in the medical record.
                </v-card-text>
                <v-row align="start" no-gutters>
                  <v-checkbox label="Patient consent has been documented" :rules="rules.required" v-model="consent">
                  </v-checkbox>
                </v-row>
              </v-card>
            </v-card>
          </v-row>
        </v-form>
        <v-row class="pb-4 px-4 pt-6" no-gutters>
          <v-col>
            <v-btn @click="submitForm" color="primary" depressed block>
              {{ nextButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row no-gutters align="start" class="fill-height px-5">
          <v-card v-if="enrollmentError && !deviceAssignmentSuccess" class="flex" height="400" flat>
            <v-row align="center" justify="center" no-gutters>
              <v-icon size="90">mdi-alert-circle</v-icon>
            </v-row>
            <v-row justify="center" style="text-align: center" no-gutters class="mx-7 py-4">
              {{ enrollmentError }}
            </v-row>
            <v-row no-gutters justify="center">
              <v-btn depressed @click=";(enrollmentProgress = 1), (enrollmentError = null)">Enter new device ID</v-btn>
            </v-row>
          </v-card>
          <v-card v-if="deviceAssignmentSuccess" class="flex" height="400" flat>
            <v-row align="center" justify="center" no-gutters>
              <v-icon color="green" size="90">mdi-check-circle</v-icon>
            </v-row>
            <v-row justify="center" style="text-align: center" no-gutters class="mx-7 py-4">
              <span v-if="selectedDevice.selectedEnrollmentOption === 'assign'">Device assigned</span>
              <span v-if="selectedDevice.selectedEnrollmentOption === 'order'">Device ordered</span>
            </v-row>
          </v-card>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.enrollment {
  .form {
    // background: red;
    [class^='col-'],
    .col {
      // background: yellow;
      padding-top: 0;
      // padding-bottom: 0;
    }
    .v-input {
      padding-top: 0;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
  }
  .product-form-disabled {
    opacity: 0.65;
  }
}
.rotate {
  transform: rotate(-180deg);
  transition: transform 1000ms ease;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>

<script>
import { computed, defineComponent, reactive, ref, watch, onMounted } from '@vue/composition-api'
import { lookupProblem } from '@/nurse-sidekick'
import dayjs from 'dayjs'

import stateList from '../../shared-ui/store/consts/states'
import { state as ssoState } from '../../shared-ui/store/sso'
import { markPatientAsEnrolled } from '../../shared-ui/store/enrollment'
import { state as rpmState, assignDevice, createPatient, getPatient } from '../../shared-ui/store/rpm'
import { updatePatientSmsPhone, getPatientBySms } from '../../shared-ui/store/patient'
import { updatePatient } from '../../shared-ui/store/patient'
import { createOrder } from '../../shared-ui/store/order'

import { DEVICES, DEVICES_KIND_MAP } from '@/consts'
import { validatePhone } from '../../shared-ui/helpers/data'

export default defineComponent({
  components: {},
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
    protoPatient: {
      type: Object,
      default: null,
    },
    currentPatient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // conditions
    const selectedConditionObject = ref(null)
    const isLoadingConditions = ref(false)
    const search = ref(null)
    const items = ref([])

    watch(search, search => {
      fetchMatchingConditions(search)
    })

    const fetchMatchingConditions = async terms => {
      items.value = await lookupProblem(terms)
    }

    const phoneValidated = ref(false)

    const consent = ref(false)
    const patient = ref(null)
    const deviceElem = ref(null)
    const selectedAssign = ref(null)
    const selectedDevice = ref(null)
    const selectedConsentOption = ref('consent')
    const defaultFormValues = {
      firstName: '',
      lastName: '',
      email: '',
      primaryPhone: '',
      smsPhone: '',
      gender: '',
      birthdate: '',
      medicareAdvantageNumber: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
      deviceId: '',
      selectedEnrollmentOption: 'assign',
    }

    let formInput = reactive(Object.assign({}, defaultFormValues))
    const morePatientInfo = ref(false)
    const enrollOptions = [
      { label: 'Assign a device in office', value: 'assign' },
      { label: 'Ship a device', value: 'order' },
    ]

    const consentOptions = [
      { label: 'Document consent now', value: 'document' },
      { label: 'Forward to Arrowhealth Enrollment', value: 'forward' },
    ]
    const enrollmentProgress = ref(0)

    const patientAlreadyEnrolled = computed(() => {
      if (rpmState.patient) {
        return true
      }
      return false
    })

    // TODO: retrieve this from database
    const products = DEVICES.sort((a, b) => (a.availableForOrder < b.availableForOrder ? 1 : -1))

    const PRODUCT_KIND_MAP = DEVICES_KIND_MAP

    const rules = {
      requireDevice: [v => !!v || 'Device must be selected'],
      BODYTRACE: [v => !!v || 'Device ID is required', v => validateIMEI(v) || 'Device ID must be valid'],
      IGLUCOSE: [v => !!v || 'Device ID is required'],
      LOHMAN: [v => !!v || 'Device ID is required', v => validateLOHMAN(v) || 'Device ID must be valid'],
      requiresValidState: stateList => [v => !!v || 'Required', v => stateList.includes(v)],
      required: [v => !!v || 'Required'],
      email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      phone: [
        v => !!v || 'Phone is required',
        v => validatePhone(v) || 'Phone must be valid',
        v => phoneValidationRule(v) || phoneValidationResult.value,
      ],
    }

    const patientForm = ref(null)
    const deviceForm = ref(null)

    const phoneValidationRule = () => {
      return phoneValidated.value
    }

    const validateLOHMAN = value => {
      return /^[a-zA-Z0-9]{6}$/.test(value)
    }

    const validateIMEI = value => {
      // Accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false

      // The Luhn Algorithm. It's so pretty.
      let nCheck = 0,
        bEven = false
      value = value.replace(/\D/g, '')

      for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10)

        if (bEven && (nDigit *= 2) > 9) nDigit -= 9

        nCheck += nDigit
        bEven = !bEven
      }

      return nCheck % 10 == 0
    }

    const clearForm = () => {
      patientCreateUpdateError.value = null
      enrollmentError.value = null
      selectedConditionObject.value = null
      consent.value = null
      enrollmentProgress.value = 0
      processingSubmission.value = false
      deviceSelections.value = []
      selectedDevice.value = null
      formInput = Object.assign(formInput, defaultFormValues)
      if (patientForm.value) {
        patientForm.value.resetValidation()
      }
      if (deviceForm.value) {
        deviceForm.value.resetValidation()
      }
    }

    const selectedCondition = condition => {
      selectedDevice.value.associatedCondition = condition
    }

    const mask = {
      phone: '###-###-####',
      zip: '#####',
      birthdate: '##/##/####',
    }

    const isPatientFormValid = ref(false)
    const isDeviceFormValid = ref(false)
    const isDeviceFormValid2 = ref(false)
    const invalidSmsPhone = ref(false)

    const deviceSelections = ref([])

    const genderOptions = ['MALE', 'FEMALE']

    const deliveryMethod = ref(0)

    const formatDate = date => {
      if (!date) return null
      return dayjs(date.split('T').shift()).format('MM/DD/YYYY')
    }

    const todayDate = ref(dayjs(new Date()).format('MM/DD/YYYY'))
    const checkingPhone = ref(false)
    const sharedPhone = ref(false)

    const populateForm = async patientInfo => {
      formInput.firstName = patientInfo.firstName
      formInput.lastName = patientInfo.lastName
      formInput.gender = patientInfo.gender
      formInput.birthdate = formatDate(patientInfo.dob)
      formInput.primaryPhone = patientInfo.primaryPhone
      formInput.smsPhone = patientInfo.smsPhone
      formInput.email = patientInfo.email
      formInput.address = patientInfo.address || {}
      if (patientForm.value) {
        patientForm.value.validate()
      }
    }
    const phoneValidationResult = ref(null)
    const checkSMSPhone = async v => {
      phoneValidationResult.value = null
      if (v && v.length > 11) {
        checkingPhone.value = true
        let phoneOwner = await getPatientBySms(v)
        if (
          phoneOwner &&
          props.currentPatient &&
          props.protoPatient.org &&
          phoneOwner.org.patientId === props.protoPatient.org.patientId
        ) {
          phoneValidated.value = true
          return true
        } else if (!phoneOwner) {
          phoneValidated.value = true
          return true
        } else if (phoneOwner) {
          phoneValidationResult.value = 'Note: Phone number in use by another patient'
          phoneValidated.value = true
          sharedPhone.value = true
          // make this the primary phone
          return false
        }
        checkingPhone.value = false
      }
      return false
    }

    const enrollmentError = ref(null)
    const patientCreateUpdateError = ref(null)
    const patientCreateUpdateSuccess = ref(false)
    const deviceAssignmentSuccess = ref(false)
    const processingSubmission = ref(false)

    const handleDeviceClick = device => {
      device.selected = !device.selected

      if (selectedDevice.value && selectedDevice.value.title === device.title) {
        selectedDevice.value = null
        device.selected = false
      } else {
        //deviceSelections.value.push(device)
        device.selectedEnrollmentOption = 'assign'
        device.readyToAssign = false
        device.associatedCondition = null
        selectedDevice.value = device
        enrollmentProgress.value = 1
        device.deviceId = null
        selectedConditionObject.value = null
      }
    }

    const submit = async () => {
      const ownerId = ssoState.user.orgId
      processingSubmission.value = true
      // If not a patient - create patient
      console.log('trying to submit proto paient')
      console.log(props.protoPatient)
      console.log(formInput)
      console.log(rpmState)

      if (!rpmState.patient) {
        try {
          await createPatient({
            owner: {
              id: ownerId,
              ref: 'Partners',
            },
            ownerPatientId: props.protoPatient.id,
            firstName: formInput.firstName,
            lastName: formInput.lastName,
            gender: formInput.gender,
            birthdate:
              new Date(formInput.birthdate)
                .toISOString()
                .split('T')
                .shift() + 'T00:00:00Z',
            smsPhone: !sharedPhone.value ? formInput.smsPhone : null,
            primaryPhone: formInput.smsPhone,
            email: formInput.email,
            address: formInput.address,
          })
          if (rpmState.patient && rpmState.patient.id) {
            await updatePatient({
              id: rpmState.patient.id,
              conditions: { value: [selectedConditionObject.value.compound] },
            })
          }

          patientCreateUpdateSuccess.value = true
        } catch (e) {
          console.log(e)
          patientCreateUpdateError.value = `There was an error creating the patient. Please try again. If the issue occurs again, please notify support.`
        }
      } else {
        if (!rpmState.patient.conditions) {
          rpmState.patient.conditions = []
        }

        if (selectedConditionObject.value.compound) {
          if (!rpmState.patient.conditions.includes(selectedConditionObject.value.compound)) {
            rpmState.patient.conditions.push(selectedConditionObject.value.compound)
            await updatePatient({ id: rpmState.patient.id, conditions: { value: rpmState.patient.conditions } })
          }
        }
        try {
          let phone = !sharedPhone.value ? formInput.smsPhone : null
          await updatePatientSmsPhone(rpmState.patient.id, phone)
          patientCreateUpdateSuccess.value = true
        } catch (e) {
          console.log(e)
          patientCreateUpdateError.value = `Error updating the phone number`
        }
      }

      // add the associated ICD 10 to the conditions list
      // If assign device
      if (selectedDevice.value.selectedEnrollmentOption === 'assign') {
        try {
          await assignDevice(rpmState.patient.id, selectedDevice.value.kind, selectedDevice.value.deviceId)
          deviceAssignmentSuccess.value = true
        } catch (e) {
          console.log('ERROR:', e)
          if (e === 'device already assigned') {
            enrollmentError.value = `The Device ID entered on the previous page is currently assigned and in use by another patient.`
          } else {
            enrollmentError.value = `There was an unexpected error assigning the device to the patient. If the issue occurs again, please notify support.`
          }
        }
      } else if (selectedDevice.value.selectedEnrollmentOption === 'order') {
        try {
          await createOrder({
            owner: {
              id: ownerId,
              ref: 'Partners',
            },
            ownerPatientId: rpmState.ownerPatientId,
            shippingRecipientName: rpmState.patient.firstName + ' ' + rpmState.patient.lastName,
            shippingAddress: formInput.address,
            patientId: rpmState.patient.id,
            deviceName: selectedDevice.value.name,
            forceOrder: true,
          })
          deviceAssignmentSuccess.value = true
        } catch (e) {
          enrollmentError.value = `There was an unexpected error ordering the device. If the issue occurs again, please notify support.`
        }
      } else {
        enrollmentError.value = `The option you selected is not recognized by our system. Please notify support.`
      }

      await markPatientAsEnrolled(rpmState.patient.id)
      //await getPatient(rpmState.patient.org.id)
      // show success screen?
      processingSubmission.value = false
      if (enrollmentError.value) {
        emit('enrollmentFlowCompleteWithErrors')
      } else {
        setTimeout(function() {
          emit('enrollmentFlowComplete')
        }, 1500)
      }
    }

    const selectedEnrollmentOption = computed(() => {
      return formInput.selectedEnrollmentOption
    })

    const submitForm = () => {
      console.log('submitting form', isPatientFormValid.value)
      //enrollmentProgress.value = enrollmentProgress.value + 1

      if (selectedDevice.value.readyToAssign && isPatientFormValid.value) {
        // forms are looking good.
        submit()
        enrollmentProgress.value = enrollmentProgress.value + 1
      } else {
        if (patientForm.value) {
          patientForm.value.validate()
        }
        if (deviceForm.value) {
          deviceForm.value.validate()
        }
      }

      //submit()
    }

    const scrollToView = (isValid, elem) => {
      if (isValid) {
        setTimeout(() => {
          if (elem) {
            elem.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
    }

    const dialogTitle = computed(() => {
      if (patient.value && rpmState.patient) {
        return 'Assign a device'
      } else if (patient.value?.firstName && patient.value?.lastName) {
        return `Enroll ${patient.value.firstName} ${patient.value.lastName}`
      } else {
        return 'Enroll a Patient'
      }
    })

    const nextButtonDisabled = computed(() => {
      if (isPatientFormValid.value && selectedDevice.value.readyToAssign && consent.value) {
        return false
      }

      return true
    })

    const nextButtonText = computed(() => {
      if (props.currentPatient) {
        return 'Assign device'
      }
      return 'Enroll Patient'
    })

    onMounted(async () => {
      clearForm()
      if (props.currentPatient && !rpmState.patient) {
        await getPatient(props.protoPatient.org.patientId)
        props.protoPatient.dob = props.protoPatient.birthdate
        props.protoPatient.smsPhone = rpmState.patient.smsPhone
        if (rpmState.patient.smsPhone !== null && rpmState.patient.smsPhone.length > 11) {
          phoneValidated.value = true
        }
      } else if (props.currentPatient) {
        props.protoPatient.dob = props.protoPatient.birthdate
        props.protoPatient.smsPhone = rpmState.patient.smsPhone
        if (rpmState.patient.smsPhone !== null && rpmState.patient.smsPhone.length > 11) {
          phoneValidated.value = true
        }
      }
      populateForm(props.protoPatient || {})
    })

    // scroll to next need position
    watch(isPatientFormValid, isPatientFormValid => {
      scrollToView(isPatientFormValid, deviceElem.value)
    })
    watch(selectedEnrollmentOption, selectedEnrollmentOption => {
      scrollToView(selectedEnrollmentOption, selectedAssign.value)
    })
    watch(enrollmentProgress, enrollmentProgress => {
      if (enrollmentProgress === 0) {
        selectedDevice.value = null
        selectedConditionObject.value = null
      }
    })
    return {
      checkSMSPhone,
      deliveryMethod,
      deviceElem,
      deviceForm,
      deviceSelections,
      dialogTitle,
      enrollOptions,
      enrollmentError,
      enrollmentProgress,
      formInput,
      genderOptions,
      handleDeviceClick,
      submitForm,
      isPatientFormValid,
      isDeviceFormValid,
      isDeviceFormValid2,
      invalidSmsPhone,
      mask,
      morePatientInfo,
      nextButtonDisabled,
      nextButtonText,
      patientForm,
      patient,
      patientAlreadyEnrolled,
      products,
      rules,
      selectedAssign,
      stateList,
      submit,
      selectedConditionObject,
      processingSubmission,
      selectedDevice,
      consentOptions,
      selectedConsentOption,
      PRODUCT_KIND_MAP,
      todayDate,
      consent,
      selectedCondition,
      isLoadingConditions,
      search,
      items,
      lookupProblem,
      patientCreateUpdateError,
      patientCreateUpdateSuccess,
      deviceAssignmentSuccess,
      phoneValidated,
      phoneValidationResult,
    }
  },
})
</script>
