<template>
  <bottom-sheet-panel
    :padding="false"
    tooltip="Activity"
    :activatorIcon="'mdi-calendar-multiselect'"
    title="Patient Activity"
  >
    <v-card flat class="flex pt-3" color="transparent">
      <v-date-picker event-color="primary" :events="events" no-title full-width></v-date-picker>
    </v-card>
  </bottom-sheet-panel>
</template>

<script>
import { defineComponent, onMounted, computed } from '@vue/composition-api'
import BottomSheetPanel from './BottomSheetPanel.vue'

export default defineComponent({
  components: { BottomSheetPanel },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    activity: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup(props) {
    onMounted(() => {})

    const events = computed(() => {
      return props.activity.map(function(item) {
        return item['timestamp'].substr(0, 10)
      })
    })

    return {
      close,
      events,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
