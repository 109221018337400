import Vue from 'vue'
import VueCompositionAPI, { reactive, readonly } from '@vue/composition-api'
import { onAuthChanged } from '../hooks'
import { enrollmentsClient } from './clients/enrollment'
import { patientsClient } from './clients/patients'

Vue.use(VueCompositionAPI)

export const STATUS_TYPES = {
  CALLBACK: 'callback',
  ENROLLED: 'enrolled',
  NO_ANSWER: 'no_answer',
  NOT_CONTACTED: 'not_contacted',
  NOT_INTERESTED: 'not_interested',
  NOT_PATIENT: 'not_patient',
  WRONG_PERSON: 'wrong_person',
  WRONG_PHONE: 'wrong_phone',
  CONTACT_PROVIDER: 'contact_provider',
  WILL_CONTACT_PROVIDER: 'will_contact_provider',
  OTHER: 'other',
}

const populateLastNote = candidate => {
  let logs = candidate.logs || []
  let log = logs[logs.length - 1] || {}
  candidate.lastNote = log.note || ''
}

onAuthChanged(authUser => {
  if (!authUser) {
    _state.candidate = null
    _state.candidates = null
    _state.startCallTime = 0
    _state.currentPatient = null
  }
})

const _state = reactive({
  candidate: null,
  candidates: [],
  startCallTime: 0,
  currentPatient: null,
})
export const state = readonly(_state)

export const clearCandidate = () => {
  _state.candidate = null
}

export const setCandidates = candidate => {
  _state.candidates = candidate
}

export const setStartCallTime = callTime => {
  _state.startCallTime = callTime
}

export const updateCandidate = candidate => {
  let candidates = _state.candidates
  for (let i = 0; i < candidates.length; i++) {
    if (candidates[i].id === candidate.id) {
      populateLastNote(candidate)
      candidates[i] = candidate
      break
    }
  }
}

export const setCurrentPatient = patient => {
  _state.currentPatient = patient
}

export const initCallTime = () => {
  setStartCallTime(new Date())
}

export const getCandidates = async (orgId) => {
  const { data } = await enrollmentsClient.post('/GetCandidates', {
    orgId,
    status: [STATUS_TYPES.CALLBACK, STATUS_TYPES.NO_ANSWER, STATUS_TYPES.NOT_CONTACTED],
  })
  for (let i = 0; i < data.candidates?.length; i++) {
    let candidate = data.candidates[i]
    populateLastNote(candidate)
  }
  setCandidates(data.candidates || [])
  return _state.candidates
}

export const getCandidate = async id => {
  let { data } = await enrollmentsClient.post('/GetCandidate', { id })
  _state.candidate = data.candidate
  return _state.candidate
}

export const findCandidate = async (ownerId, ownerPatientId) => {
  let { data } = await enrollmentsClient.post('/FindCandidate', {
    ownerId,
    ownerPatientId,
  })
  _state.candidate = data.candidate
  return _state.candidate
}

export const checkDeviceAvailability = async (kind, deviceRefId) => {
  let { data } = await patientsClient.post('/GetDevice', {
    kind,
    deviceRefId,
  })
  return !data.device
}

export const markPatientAsEnrolled = async patientId => {
  try {
    await patientsClient.post('/MarkPatientAsEnrolled', { id: patientId })
    return true
  } catch (e) {
    return false
  }
}
