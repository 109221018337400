<template>
  <v-row v-resize="setWidths" no-gutters>
    <v-col cols="3">
      <v-row no-gutters>
        <v-card flat tile :color="selectedPanel === 0 ? 'grey lighten-4' : 'grey lighten-4'" class="flex">
          <v-card color="transparent" flat class="novoHeaderCellStyle flex" tile>
            <v-row no-gutters align="center" justify="center" class="fill-height text-subtitle-2">
              Time
            </v-row>
          </v-card>
          <v-divider />
          <v-simple-table style="background-color: transparent" tile>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in allEvents" :key="index">
                  <td
                    style="text-align: center; border-right: 1px solid #eeeeee; height:50px; background-color: transparent"
                  >
                    <v-row justify="start" no-gutters>
                      {{ formatDate(item.timestamp) }}
                    </v-row>
                    <v-row justify="start" class="text-caption text--secondary" style="margin-top:-5px;" no-gutters>
                      {{ formatTime(item.timestamp) }}
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-row>
    </v-col>
    <v-col cols="9">
      <v-slide-group v-model="selectedColumn" mandatory center-active :show-arrows="false">
        <v-slide-item v-slot="{ active, toggle }" v-for="(column, index) in columnArray" :key="index + 'panel'"
          ><v-card
            class="flex"
            flat
            tile
            :width="columnWidth"
            :color="selectedPanel === index || active ? 'blue lighten-5' : 'white'"
            v-show="index > 0"
            @click="toggle, $emit('updateFocus', index)"
          >
            <v-card color="transparent" flat class="novoHeaderCellStyle flex" tile>
              <v-row no-gutters align="center" justify="center" class="fill-height text-subtitle-2">
                {{ column.abbreviation }}
              </v-row>
            </v-card>
            <v-divider />
            <v-simple-table
              style="border-radius: 0px; background-color: transparent"
              light
              class="transparent"
              fixed-header
            >
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, index) in allEvents" :key="index">
                    <td
                      style="text-align: center; border-right: 1px solid #eeeeee; height:50px; padding:0px;"
                      @click="focusReading(item, column, index)"
                    >
                      <v-row justify="center" align="center" class="text-caption" no-gutters>
                        {{ item.data[column.abbreviation] }}
                        <v-badge
                          v-if="column.kind === 'Ecg' && item.data.pdfUrl"
                          overlap
                          color="red"
                          :value="!!item.data.afib"
                          dot
                        >
                          <v-btn x-small color="primary" icon @click="$emit('showPDF', item.data)">
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </v-badge>
                      </v-row>
                      <v-row
                        justify="center"
                        v-if="item.data[column.abbreviation]"
                        class="text-caption text--disabled"
                        style="margin-top:-5px;"
                        no-gutters
                      >
                        {{ column.units }}
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: null,
    },
    allEvents: {
      type: Array,
      default: () => [],
    },
    columnArray: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },

    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
    selectedPanel: {
      type: Number,
      default: 0,
    },
    focusMetric: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const columnWidth = ref(90)
    const selectedColumn = ref(0)

    const setWidths = () => {
      let width = window.innerWidth / 4

      if (width < 100) {
        width = 100
      }
      // if there is space for each of the columns to be 120, jsut divide them equally
      if (((window.innerWidth / 4) * 3) / props.tableHeaders.length > 100) {
        width = ((window.innerWidth / 4) * 3) / props.tableHeaders.length
      } else {
        if (props.tableHeaders.length <= 3) {
          width = ((window.innerWidth / 4) * 3) / props.tableHeaders.length
        }
        if (props.tableHeaders.length > 3) {
          width = window.innerWidth / 4.3
        }
      }
      columnWidth.value = width
    }

    const formatDate = dt => {
      return dayjs(dt).format('M/D/YY')
    }

    const formatTime = dt => {
      return dayjs(dt).format('h:mm A')
    }

    const focusReading = (item, column, index) => {
      console.log('selected: ', item, column, index)
    }

    const zoomTo = index => {
      if (index) {
        selectedColumn.value = index
      } else {
        selectedColumn.value = index
      }
    }

    onMounted(() => {
      props.tabs
    })

    return {
      columnWidth,
      formatTime,
      formatDate,
      setWidths,
      zoomTo,
      selectedColumn,
      focusReading,
    }
  },
})
</script>
<style scoped></style>
<style scoped>
.novoCellStyle:hover {
  background-color: #e0e0e0;
}

.focusColumn {
  background-color: #e3f2fd;
  color: #101010;
}
.novoTimeCellStyle {
  background-color: #f5f5f5;
  color: #101010;
}

.novoHeaderCellStyle {
  border-right: 1px solid #eeeeee;
  color: #424242;
  height: 50px;
}
</style>
