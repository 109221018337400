<template>
  <v-bottom-sheet scrollable v-model="sheet">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="iconColor" icon v-bind="attrs" v-on="on" @click="$emit('active')">
        <v-badge :value="badge" dot overlap bordered>
          <v-tooltip nudge-bottom="10" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ activatorIcon }}</v-icon>
            </template>
            {{ tooltip }}
          </v-tooltip>
        </v-badge>
      </v-btn>
    </template>

    <v-row v-if="false" no-gutters>
      <v-card tile class="flex pb-4">
        <v-row class="pt-3 px-3" align="center" no-gutters>
          <v-icon :color="iconColor" class="mr-3">{{ activatorIcon }}</v-icon>
          <span class="text-h6 font-weight-bold text--secondary">{{ title }}</span>
          <v-spacer />
          <v-btn @click="sheet = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-row>
        <v-row v-if="padding" no-gutters class="px-4 pt-4">
          <slot> </slot>
        </v-row>
        <v-row v-else no-gutters class="px-0 pt-0">
          <slot> </slot>
        </v-row>
      </v-card>
    </v-row>
    <v-card v-resize="onResize" :max-height="maximumCardHeight" tile flat>
      <v-toolbar height="64" flat>
        <span class="text-h6 font-weight-bold text--secondary">{{ title }}</span>
        <v-spacer />
        <v-btn @click="sheet = false" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text v-if="padding" class="pa-4 pt-0"><slot> </slot></v-card-text>
      <v-card-text v-if="!padding" class="pa-0"><slot> </slot></v-card-text>
      <v-card-actions class="mb-2">
        <slot name="actionSlot"> </slot>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    activatorIcon: {
      type: String,
      default: 'mdi-tortoise',
    },
    title: {
      type: String,
      default: 'Sheet Title',
    },
    tooltip: {
      type: String,
      default: 'tooltip',
    },
    iconColor: {
      type: String,
      default: null,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    openOnCreate: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 75,
    },
    requiredHeight: {
      type: Number,
      default: null,
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['active'],
  setup(props) {
    const maximumCardHeight = ref(window.innerHeight * (props.maxHeight / 100))
    const sheet = ref(false)

    const onResize = () => {
      maximumCardHeight.value = window.innerHeight * (props.maxHeight / 100)
    }

    const open = () => {
      sheet.value = true
    }

    const close = () => {
      sheet.value = false
    }

    if (props.openOnCreate) {
      sheet.value = true
    }

    onMounted(() => {})

    return {
      sheet,
      open,
      close,
      onResize,
      maximumCardHeight,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
