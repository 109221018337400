<template>
  <div>
    <portal to="pageTitle">
      Remote Monitoring
    </portal>
    <taskboard />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Taskboard from '../components/taskboard/Taskboard.vue'
export default defineComponent({
  components: { Taskboard },
  setup() {},
})
</script>

<style></style>
