<template>
  <bottom-sheet-panel tooltip="Devices" :activatorIcon="'mdi-devices'" title="Connected Devices">
    <v-row no-gutters>
      <v-card flat class="flex" color="transparent">
        <v-card color="grey lighten-4" class="mb-4 " flat v-for="(device, index) in devices" :key="index">
          <v-row class="pt-4 px-3 pl-2" v-if="deviceKindDictionary[device.kind]" no-gutters>
            <v-card class="mr-2" flat color="transparent" width="65" height="50">
              <v-row no-gutters class="fill-height" justify="center" align="center">
                <v-img max-width="50" max-height="50" contain :src="deviceKindDictionary[device.kind].image" />
              </v-row>
            </v-card>
            <v-col>
              <div>
                <span style="font-weight:bold; font-size: 16px;">{{ deviceKindDictionary[device.kind].title }}</span>
              </div>
              <div>
                <span>{{ device.kind }}</span>
              </div>
              <div>
                <span>{{ device.deviceRefId }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row v-else no-gutters class="text-uppercase text-subtitle-2 px-3 pt-4" align="center"
            ><v-icon class="mr-2">mdi-cellphone-settings mdi-rotate-270</v-icon
            ><span class="text-truncate" style="max-width: 100px;">{{ device.kind }}</span
            ><span class="text--secondary ml-2">{{ device.deviceRefId }}</span> <v-spacer />
          </v-row>
          <v-row no-gutters>
            <v-spacer /><v-btn @click="removeDeviceFromPatient(device)" class="pa-5 py-6" color="red" text
              >unassign</v-btn
            >
          </v-row>
        </v-card>
        <empty-page
          :shrink="true"
          title="No connected devices yet"
          detail="Any connected devices assigned to this patient will appear here."
          :image="require('@/assets/nodata.svg')"
          v-if="devices.length === 0"
        />
      </v-card>
    </v-row>
    <enrollment-flow-modal-view
      @refreshDeviceList="$emit('refreshDeviceList')"
      slot="actionSlot"
      :patient="props.patient"
    />
  </bottom-sheet-panel>
</template>

<script>
import { computed, defineComponent, onMounted } from '@vue/composition-api'
import BottomSheetPanel from './BottomSheetPanel.vue'
import EnrollmentFlowModalView from './enrollment/EnrollmentFlowModalView.vue'
import { DEVICES } from '@/consts'
import EmptyPage from './EmptyPage.vue'
import { unassignDevice } from '@/shared-ui/store/rpm'

export default defineComponent({
  components: { BottomSheetPanel, EnrollmentFlowModalView, EmptyPage },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {}
      },
    },
    devices: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  setup(props, { emit }) {
    onMounted(() => {})

    const deviceKindDictionary = computed(() => {
      const dict = {}
      DEVICES.forEach(device => {
        dict[device.kind] = { image: device.image, title: device.name, desc: device.description }
      })
      return dict
    })

    const removeDeviceFromPatient = async device => {
      await unassignDevice(device.id)
      emit('refreshDeviceList')
    }

    return {
      close,
      props,
      deviceKindDictionary,
      removeDeviceFromPatient,
    }
  },
})
</script>

<style scoped>
.notificationArea {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  max-width: 375px;
}
</style>
