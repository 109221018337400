<template>
  <bottom-sheet-panel tooltip="Export" activatorIcon="mdi-cloud-print-outline" title="Export Facesheet">
    <v-row no-gutters>
      <v-card flat class="flex" color="transparent">
        <v-row no-gutters>
          <v-card-text class="pa-0">
            The patient facesheet contains basic patient demographics and lists the most recent measurements in the
            patient's chart. The facesheet can be generated for the previous 1, 3, 6, or 12 months. Selecting a range
            below and choosing download will generate a PDF.</v-card-text
          >
        </v-row>
        <v-row class="mt-5" no-gutters>
          <v-select v-model="numberOfMonths" hide-details outlined dense flat :items="options"> </v-select>
        </v-row>
      </v-card>
    </v-row>
    <v-btn slot="actionSlot" class="flex" color="primary" depressed @click="download()">Download</v-btn>
  </bottom-sheet-panel>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { exportFacesheet } from '../shared-ui/helpers/pdf'
import BottomSheetPanel from './BottomSheetPanel.vue'

export default defineComponent({
  components: { BottomSheetPanel },
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const numberOfMonths = ref(1)
    const show = ref(false)
    const options = [
      { text: '1 month', value: 1 },
      { text: '3 months', value: 3 },
      { text: '6 months', value: 6 },
      { text: '12 months', value: 12 },
    ]

    const download = () => {
      let start = dayjs().subtract(numberOfMonths.value, 'months')
      exportFacesheet(props.patient, start)
    }

    const open = () => {
      show.value = true
    }

    return {
      download,
      numberOfMonths,
      options,
      open,
      show,
    }
  },
})
</script>
