import { render, staticRenderFns } from "./PatientNotificationManager.vue?vue&type=template&id=7039a59e&scoped=true&"
import script from "./PatientNotificationManager.vue?vue&type=script&lang=js&"
export * from "./PatientNotificationManager.vue?vue&type=script&lang=js&"
import style0 from "./PatientNotificationManager.vue?vue&type=style&index=0&id=7039a59e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7039a59e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VRow,VScaleTransition,VSpacer})
